import React from 'react';
import jsonData from '../jsonData'
import FindNearestCard from './FindNearestCard'

function FindNearest(props){

    let cardsData = jsonData.find_nearest_cards;

    let cardDisplay = cardsData.map(card =>{
        return(
            <FindNearestCard    key={card.id} card={card} 
                                setFilterSettings = {props.setFilterSettings}
                                filterSettings = {props.filterSettings}
                                />
        )
    });

    return(
    <div className="home">
        <div className="container">
            <div className="row justify-content-center  ">
                {cardDisplay}
            </div>
        </div>
    </div>
    );
}
export default FindNearest;