import React from 'react'

function CompareSectionCard(props){

    let contentDisplay = props.card.content.map( content => {
        return(
            <div key={content.id} className="row no-gutters diff">
                    <div className="col-1">
                        <i className={(content.icon)}></i>
                    </div>
                    <div className="col-11">
                        <dl className="custom">
                            <dt className="mb-3">{content.question}</dt>
                            <dd>{content.answer}</dd>
                        </dl>
                    </div>
                </div>
        )
    })
    return(
        <div className="col-lg-6">
            <div className="box border p-4 p-md-5 m-4 br-20 shadow diff-box h-100">
                <img src={("../img/ico/" + props.card.image)} className="mb-4" alt=""/>
                <h3 className="mb-5">{props.card.header} <span className={props.card.color}>{props.card.subheader}</span></h3>
                
                {contentDisplay}
            </div>
        </div>
    );
}

export default CompareSectionCard;