import React from 'react';

const About = ({title, about}) => {

    const aboutUs = about.about
    return (
        <>
            {(aboutUs.trim() == '' ||  aboutUs.trim().length == 0 )?
                null:
                    <div className="col">
                        <h5>{title}</h5>
                        <p className="my-4">{about.about}</p>
                    </div>
            }
        </>
    )
}

export default About;