import React from 'react';
import TestIcon from "./TestIcon";

const TestingInfoCard = ({tests, title, description}) => {
    console.log('THIS IS A TEST');
    console.log(tests);
    const services = tests.map((test) => {
        switch(test.test.type) {
            case "nasal swab" || "Nasal Swab" :
                return <TestIcon title="Nasal swab"
                                 src="../../img/ico/test-01.svg" />;
            case "blood" || "Blood":
                return <TestIcon title="Blood draw"
                                 src="../../img/ico/test-03.svg" />;
            case "finger" || "Finger":
                return <TestIcon title="Finger prick"
                                 src="../../img/test-02.svg" />;
            case "spit" || "Spit":
                return <TestIcon title="Spit test"
                                 src="../../img/ico/test-04.svg" />;
            case "rapid test" || "Rapid Test":
                return <TestIcon title="Rapid test"
                                src="../../img/ico/icon-rapid-test.svg" />;
            default:
                return null;
        }
    });

    console.log('These are the icons.');
    console.log(services);

    const imageFiles = {
        antibodyTesting: "../../img/ico/ico-2-sm.svg",
        covidTesting: "../../img/ico/ico-1-sm.svg"
    }
    const cardForAntibodyTesting = title === "COVID-19 Antibody Testing";
    return (
        <div className="col-lg-6 mb-3 mb-md-3 mb-lg-0">
            <div className="box border p-4 p-md-5 m-1 m-md-0 br-20 diff-box h-100">
                <img src={cardForAntibodyTesting ? imageFiles.antibodyTesting : imageFiles.covidTesting} alt="..." className="mb-4" />
                    <h5 className="mb-4 mb-md-5 mb-lg-4">{title}</h5>
                    <p className={cardForAntibodyTesting ? "" : "cus-space"} style={{marginBottom: cardForAntibodyTesting ? "20px" : "70px"}}>{description}</p>
                    {(tests.length < 1) ?
                        <p className="no-test">Please call for more information.</p> :
                        <p className="mb-4">Test{services.length > 1 ? "s" : ""} offered here</p>
                    }
                    <div className="row react test-row">
                        {services}
                    </div>
            </div>
        </div>
    )
}

export default TestingInfoCard;