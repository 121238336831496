import React, { useState } from 'react';
import {getClinicsNearUserLocation, getClinicsNearZIPLocation, getNearestClinics} from "../../Client";
import ClinicCard from './ClinicCard';
import { isMobile } from "react-device-detect";



function FilterSection(props){
    let abortController = new AbortController();
    let [userZip, setUserZip] = useState(localStorage.getItem('userZip'));

    let [sliderValue, setSliderValue] = useState(props.radius);

    function length(){
        let message = `${props.message}`;
        return message;
    }
    function filterOnChangeHandler(e){
        let value = e.target.value;
        
        props.setFilterSettings({...props.filterSettings,
            [e.target.name] : e.target.checked
        })
        // props.filterClinics()
        // let checkedClinics = props.clinics.filter((clinic) => {
        //     if(props.filterSettings.covidTest && props.filterSettings.antibodyTest){
        //       return clinic.covidTestServices.length !== 0 || clinic.antibodyTestServices.length !== 0
        //     }
        //     if (props.filterSettings.covidTest && !props.filterSettings.antibodyTest) {
        //       return clinic.covidTestServices.length !== 0
        //     }
        //     if (props.filterSettings.antibodyTest && !props.filterSettings.covidTest) {
        //       return clinic.antibodyTestServices.length !== 0;
        //     }
        //     if(!props.filterSettings.antibodyTest && !props.filterSettings.covidTest){
        //       return clinic;
        //     }
        //   })

    }
    // new functions
    let covidClinicsDisplay = props.covidClinics.map((clinic)=>{
        return(
            <ClinicCard key={clinic.id}
                        id={clinic.practice_id}
                        clinic = {clinic}
                        name={clinic.name}
                        city={`${clinic.city}, ${clinic.state}`}
                        ratings={5}
                        premier={false}
                        userLocation={props.userLocation}
                        clinicDetails = {props.clinicDetails}
                        setClinicDetails = {props.setClinicDetails}
                        />
        );
    })
    // let antibodyClinicsDisplay = props.antiBodyClinics.map((clinic)=>{
    //     return(
    //         <ClinicCard key={clinic.practice.id}
    //                     id={clinic.practice.practice_id}
    //                     clinic = {clinic}
    //                     name={clinic.practice.name}
    //                     city={`${clinic.practice.city}, ${clinic.practice.state}`}
    //                     ratings={5}
    //                     premier={false}
    //                     userLocation={props.userLocation}
    //                     clinicDetails = {props.clinicDetails}
    //                     setClinicDetails = {props.setClinicDetails}
    //                     />
    //     );
    // })
    // let covidAndAntibodyClinicsDisplay = props.covidAndAntibodyClinics.map((clinic)=>{
    //     return(
    //         <ClinicCard key={clinic.practice.id}
    //                     id={clinic.practice.practice_id}
    //                     clinic = {clinic}
    //                     name={clinic.practice.name}
    //                     city={`${clinic.practice.city}, ${clinic.practice.state}`}
    //                     ratings={5}
    //                     premier={false}
    //                     userLocation={props.userLocation}
    //                     clinicDetails = {props.clinicDetails}
    //                     setClinicDetails = {props.setClinicDetails}
    //                     />
    //     );
    // })

    // function handleCheckboxMouseEvent(event){
        
    //     if (event.type === "mouseup" || event.type === "touchend") {
    //         props.filterClinics();
    //     }
    // }
    let handleRadiusChange = (event) => {
        setSliderValue(event.target.value)
    }

    function handleInputEvent(event){
        if (event.type === "mousedown" || event.type === "touchstart"){
            localStorage.removeItem("userZip");
            setUserZip("")
        }
    }

    function handleMouseEvent(event){    
        if (event.type === "mouseup" || event.type === "touchend") {        
            props.setLoading(true)
            props.setRadius(event.target.value)
            let params = {
                lat: props.userLocation.lat,
                long: props.userLocation.lng,
                radius: event.target.value,
            }
            
            getClinicsNearZIPLocation(params, abortController).then(
                function (clinics) {           
                    if(clinics.length < 1){
                        getNearestClinics({ lat: params.lat, long: params.long }, abortController).then(
                            function (res) {
                              props.setClinics(res)
                              props.setNearestClinicsFlagZipBased(false)
                              props.setNearestClinicsFlagRadiusBased(true)
                            //   props.setMessage(`There are no testing centers within ${params.radius}mi radius. Showing you the nearest testing center${res.length > 1 ? "s" : ""}.`);
                              props.setLoading(false)
                              return res
                          })

                    } else {
                        props.setNearestClinicsFlagRadiusBased(false)
                        props.setNearestClinicsFlagZipBased(false)
                        // props.setMessage(`There are ${clinics.length} testing center${clinics.length > 1 ? "s" : ""} near you.`)
                        props.setClinics(clinics)
                        props.setLoading(false)
                    }
                }
            )
        };
    };

    

    return(
        <React.Fragment>
            <div className="find-box mb-4">
                <div className="row no-gutters">
                    <div className="col-md-12 col-lg-6">
                        <div className="border br-10 p-3">
                            <div className="row no-gutters">
                                <div className="col-md-4 text-left pr-3">
                                    <label htmlFor="zip">Zip Code</label>
                                    <input  onChange={props.zipOnChangeHandler}
                                            type="text" 
                                            className={"form-control mb-3 mb-lg-0 mb-xl-0 w-100 " + (isMobile ? "mobile-search-input" : "") }
                                            onMouseDown={handleInputEvent}
                                            onTouchStart={handleInputEvent}
                                            placeholder={userZip}
                                            required=""  />
                                </div>
                                <div className="col-md-8 text-center">
                                    <button className="btn btn-cta-2" onClick={()=>{props.zipOnClickHandler()}} href="#">Find My Location</button>
                                </div>
                                {props.errorDisplay()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="result-info mb-5 text-left">
                <p id='resultMessage'>{length()}</p>
                <h5 id='resultAddress' className="b18 my-4">{props.userAddress}</h5>
                <div className="row">
                    <div className="col-2 col-md-2">
                        Filter:
                    </div>
                    <div className="col-10 col-md-10">
                        <form>
                            <div className="form-group row align-items-center">
                                <div className="col-4 col-sm-3 col-md-4 col-lg-2">
                                    <label htmlFor="formControlRange">Radius: <span id="demo">{sliderValue}</span>mi</label>
                                </div>
                                <div className="col-8 col-sm-8 col-md-3 col-lg-10 px-0">
                                    <div className="slidecontainer">
                                        <input type="range" min="1" max="100" name="radius"
                                            defaultValue={props.radius} 
                                            onChange={handleRadiusChange}
                                            onMouseDown={handleMouseEvent}
                                            onMouseUp={handleMouseEvent}
                                            onTouchStart={handleMouseEvent}
                                            onTouchEnd={handleMouseEvent}
                                            className="slider" id="myRange" 
                                            disabled={props.error}/>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-checkbox">
                                <label className="container">Covid Testing
                                    <input type="checkbox" onChange={filterOnChangeHandler}                                      
                                        name="covidTest" 
                                        checked={props.filterSettings.covidTest} 
                                        disabled={props.error}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="custom-checkbox">
                                <label className="container">Antibody Testing
                                    <input type="checkbox" onChange={filterOnChangeHandler}
                                        name="antibodyTest" 
                                        checked={props.filterSettings.antibodyTest}
                                        disabled={props.error} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>


                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>


    );
}

export default FilterSection;