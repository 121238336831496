import React from 'react';
import { Link } from 'react-router-dom';
import jsonData from './jsonData';

function Footer(props){
    let footer = jsonData.footer;
    let socialLinks = footer.social_links;
    let companyLinks = footer.company_links;

    let socialDisplay = socialLinks.map( link => {
        return(
            <a key={link.id} href={(link.url)}><i className={(link.icon)}></i></a>
        );
    })

    let companyDisplay = companyLinks.map(link => {
        return(
            <div key={link.id} className="col-md-6 text-center mb-4 mb-md-0">
                {/* <span><img src={("../img/ico/" + link.logo)} alt={link.website} className=" mr-1" />{link.title}</span>
                <a href={(link.url)} className="ml-2">{link.website}</a> */}
                <span><img src={("../img/ico/" + link.logo)} alt={link.website} className=" mr-1" />{link.title}</span>
                <p className="ml-2 footer-links">{link.website}</p>
            </div>
        );
    })
    return(
    <React.Fragment>
        <div className="footer" id="foot">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-4 mb-md-5">
                        <div className="row no-gutters">
                            <div className="col-md-6 text-center text-md-left mb-4 mb-md-0">
                                <Link to="/" onClick={props.logoOnClickHandler}><img src={("../img/" + jsonData.logo)} alt="Antibody-testing" /></Link>
                            </div>
                            <div className="col-md-6 text-center text-md-right social">
                                {socialDisplay}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 col-12">
                        <div className="row no-gutters text-left">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h5 className="mb-4">{footer.title}</h5>
                                <ul className="list-unstyled">
                                
                                    <li className="mb-2"><Link to="/about">About Us</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h5 className="mb-4">Reach us at</h5>
                                <ul className="list-unstyled">
                                        {/* <li className="mb-2">
                                            <a href="#">
                                                <img src="img/ico/ic-contact-phone.svg" alt="Contact Number" className="mr-2" />{footer.contact_number}
                                            </a>
                                        </li> */}
                                        <li className="mail mb-4">
                                            <a href="mailto:contact@urgentcare.com">
                                                <i className="fas fa-envelope mr-2"></i>
                                                {footer.email}
                                            </a>
                                        </li>

                                        <Link to="/privacy-policy">
                                            <li className="mail mb-4">
                                                Privacy Policy
                                            </li>
                                        </Link>
                                        <Link to="/terms-of-use">
                                            <li className="mail mb-4">
                                                Terms of Use
                                            </li>
                                        </Link>
                                    </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 mb-md-0 text-center">
                    </div>
                    <div className="col-md-4 text-center text-md-right">
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright text-center">
            <div className="container">
                <div className="row">
                    <div className="col">
                        Copyright © 2021 {footer.title}
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-2">
            <div className="container">
                <div className="row">
                   {companyDisplay}
                </div>
            </div>
        </div>
    </React.Fragment>

    );
}

export default Footer;