import React from 'react';
import jsonData from './jsonData'
import { Link }from 'react-router-dom'
import Helmet from 'react-helmet';


function Navbar(props){
    let logo = jsonData.logo;
    
    function findNearestClickHandler(){
        props.setFilterSettings({...props.filterSettings, 
            covidTest: true,
            antibodyTest: true,
        });
    }
    
  
    return(
        <nav className="navbar navbar-expand-md navbar-light top-nav sticky-top">
            <Helmet>
                <title>AntibodyTesting.com: Find Your Local Antibody Testing Center, Get Directions, Hours of Operation and COVID-19 Testing</title>
                <meta name="description" content="Find your local Antibody Testing Center from our nationwide database of more than 14,000 locations. Listings include COVID-19 Viral and Antibody Testing information, x-ray, accepted insurance carriers, and other services." />
                <meta name="keywords" content="antibody testing, antibody testing near me" />
            </Helmet>
            <Link to="/" className="navbar-brand" onClick={()=>{props.logoOnClickHandler()}}>
                <img src={('../../img/' + logo )} alt="antibody" className="img-fluid" />
            </Link>
            <button className="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav ml-auto align-items-center">
                    <li className="nav-item">
                        <a className="nav-link" href="#foot">Contact Us</a>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-link" href="#">About Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/search" className="btn-cta mx-3" href="#" onClick={()=>{findNearestClickHandler()}}>
                            <i className="fas fa-search mr-2" aria-hidden="true"></i> Find Nearest Result
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
export default Navbar;