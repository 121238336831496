import React from 'react';
import ScheduleRow from "./ScheduleRow";
import {getTimeDecimalEquivalent, get12HourFormatTimeString, parseTimeString} from "../../Helpers";
import {getTimeByLocation} from "../../Client";

class ScheduleTable extends React.Component {
    state = {
        practiceUTCOffset: 0,
        userUTCOffset: 0,
    }

    render() {
        console.log(this.props.schedule);
        let scheduleRows = []

        if(this.props.schedule) {
            for(const [day, hours] of Object.entries(this.props.schedule)){
                // TODO: manipulate hours here
                const practice_opening = getTimeDecimalEquivalent(hours ? hours.opening : "0:00:00");
                const practice_closing = getTimeDecimalEquivalent(hours ? hours.closing : "0:00:00");

                const deltaUTCOffset = ((((this.props.userUTCOffset + this.props.userDSTUTCOffset)
                    - (this.props.practiceUTCOffset + this.props.DSTUTCOffset))) / 60) / 60;

                const user_opening = practice_opening + deltaUTCOffset;
                const user_closing = practice_closing + deltaUTCOffset;

                scheduleRows = scheduleRows.concat(<ScheduleRow day={day} hours={hours ? {
                    opening: user_opening,
                    closing: user_closing
                } : null} />);
            }
        }

        return(
            <table className="table d-block hours mb-4 table-responsive">
                <thead>
                <tr>
                    <th scope="col"><i className="fas fa-clock mr-2"></i>Hours</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {scheduleRows}
                </tbody>
            </table>
        )
    }
}

export default ScheduleTable;