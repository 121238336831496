const GOOGLE_MAPS_API_ACCESS_TOKEN = process.env.REACT_APP_GOOGLE_API_ACCESS_TOKEN;
const GOOGLE_MAPS_TIMEZONE_API_ACCESS_TOKEN = process.env.REACT_APP_GOOGLE_TIMEZONE_API_ACCESS_TOKEN;
const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
const APP_API_SMS = process.env.REACT_APP_API_SMS;
const API_ROOT_URL_V2 = process.env.REACT_APP_API_ROOT_URL_V2;

//*****************************************************************************************************************
//                                GOOGLE MAPS GEOCODING LOCATION API                                             //
//*****************************************************************************************************************

async function getCurrentPositionUsingGoogleMapsAPI() {
    const URL = `https://www.googleapis.com/geolocation/v1/geolocate?key=${getGoogleMapsAPIAccessToken()}`;
    const response = await fetch(URL, {
        method: 'POST',
    });

    const response_check = await checkStatus(response);
    const json_data = await parseJSON(response_check);

    return json_data;
}

const getAddressUsingCoordinates = ({lat, lng}) => {
    const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_ACCESS_TOKEN}`
    return fetch(URL, {
        method: 'GET',
    }).then(checkStatus)
        .then(parseJSON);
}

const getAddressUsingZIP = (zipCode) => {
    const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=ZIP+${zipCode.padStart(5,"0")},USA&key=${GOOGLE_MAPS_API_ACCESS_TOKEN}`
    return fetch(URL, {
        method: 'GET',
    }).then(checkStatus)
        .then(parseJSON);
}

//*****************************************************************************************************************
//                                  GOOGLE MAP TIMEZONE API CALLS                                                //
//*****************************************************************************************************************

const getTimeByLocation = (location, timestamp) => {
    const URL = 'https://maps.googleapis.com/maps/api/timezone/json?' +
        `location=${location ? location.lat : 15.7},${location ? location.lng: 121}` +
        `&timestamp=${timestamp}` +
        `&key=${getGoogleMapsTimezoneAPIAccessToken()}`

    return fetch(URL, {
    }).then(checkStatus)
        .then(parseJSON);
}

//*****************************************************************************************************************
//                                UTILITY FUNCTIONS FOR API RESPONSES                                            //
//*****************************************************************************************************************

const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        throw new Error("API ERROR: " + response.statusText);
    }
}

const parseJSON = (response) => {
    return response.json();
}

const aborted = (error) => {
    // if(error.name === 'AbortError') {
    //     console.log("Request aborted.");
    // }

    // console.log(error.name);
    return null;
};


//*****************************************************************************************************************
//                                              TOKEN GETTERS                                                    //
//*****************************************************************************************************************

const getGoogleMapsAPIAccessToken = () => {
    return GOOGLE_MAPS_API_ACCESS_TOKEN;
}

const getGoogleMapsTimezoneAPIAccessToken = () => {
    return GOOGLE_MAPS_TIMEZONE_API_ACCESS_TOKEN;
}

//*****************************************************************************************************************
//                                              API CALLS                                                        //
//*****************************************************************************************************************

const getClinicsFromZIP = ({zip, long, lat, radius, covidTest, antibodyTest, xray}, abortController=null) => {
    const zipFilter = zip !== "" ? "&zip=" + zip.replace(/^0+/, '') : "";
    const partnership = "&partnership=2";
    const URL = API_ROOT_URL + "?" +
          zipFilter + partnership;

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
    if(abortController) options["signal"] = abortController.signal;

    return fetch(URL, options).then(checkStatus)
        .then(parseJSON)
        .catch(aborted);
}

const getClinicsNearUserLocation = ({zip, long, lat, radius, covidTest, antibodyTest, xray},
                                    abortController=null) => {
    const latitudeFilter = lat ? "&lat=" + lat : "";
    const longitudeFilter = long ? "&long=" + long : "";
    const partnership = "&partnership=2";
    const URL = API_ROOT_URL + "?" +
        latitudeFilter +
        longitudeFilter +
        "&radius=" + radius + partnership;

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
    if(abortController) options["signal"] = abortController.signal;

    return fetch(URL, options).then(checkStatus)
        .then(parseJSON)
        .catch(aborted);
}

const getClinicsNearZIPLocation = async ({zip, long, lat, radius, covidTest, antibodyTest, xray},
                                         abortController=null) => {
    const latitudeFilter = lat ? "&lat=" + lat : "";
    const longitudeFilter = long ? "&long=" + long : "";
    const partnership = "&partnership=2";
    const URL = API_ROOT_URL + "?" +
        latitudeFilter +
        longitudeFilter +
        "&radius=" + radius + partnership;

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
    if(abortController) options["signal"] = abortController.signal;

    return fetch(URL, options).then(checkStatus)
        .then(parseJSON)
        .catch(aborted);
}

const getClinicsNearZIPLocationV2 = ({lat, long, radius}, abortController = null) => {
    const URL = API_ROOT_URL_V2 + `practice/search/?lat=${lat}&lng=${long}&radius=${radius}`;
    console.log('V2: ' + URL);

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
    if(abortController) options["signal"] = abortController.signal;

    return fetch(URL, options).then(checkStatus)
        .then(parseJSON)
        .catch(aborted);
}

const getPractice = (id, abortController=null) => {
    const partnership = "?&partnership=2";
    const URL = API_ROOT_URL + id + partnership;
    console.log(URL);
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
    if(abortController) options["signal"] = abortController.signal;
    return fetch(URL, options).then(checkStatus)
        .then(parseJSON)
        .catch(aborted);
}

const getPracticeV2 = (practice_id) => {
    const URL = API_ROOT_URL_V2 + "practice/?practice_id=" + practice_id;
    console.log(URL);
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(catchGenericError);
}

const catchGenericError = (error) => {
    return {
        error: error
    }
}

const getNearestClinics = ({zip, long, lat, radius, covidTest, antibodyTest, xray}, abortController=null) => {
    const latitudeFilter = "&lat=" + lat;
    const longitudeFilter = "&lng=" + long;
    const partnership = "&partnership=2";
    const URL = API_ROOT_URL + "nearest/?" +
        latitudeFilter +
        longitudeFilter + partnership;

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
    if(abortController) options["signal"] = abortController.signal;

    return fetch(URL, options).then(checkStatus)
        .then(parseJSON)
        .catch(aborted);
}

// distance

const getDistanceFromCurrentLocation = (from, to) => {
    const URL = API_ROOT_URL + "distance?" +
        `fromLat=${from.lat}` +
        `&fromLng=${from.lng}` +
        `&toLat=${to.latitude}` +
        `&toLng=${to.longitude}`;
    return fetch(URL, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }).then(checkStatus)
        .then(parseJSON);

}

const getDistanceFromCurrentLocationV2 = (from, to) => {
    console.log(from);
    const URL = API_ROOT_URL_V2 + "practice/distance/?" +
        `from_lat=${from.lat}` +
        `&from_lng=${from.lng}` +
        `&to_lat=${to.latitude}` +
        `&to_lng=${to.longitude}`;
    console.log(URL);
    return fetch(URL, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }).then(checkStatus)
        .then(parseJSON);

}

//*****************************************************************************************************************
//                                                  SMS API                                                      //
//*****************************************************************************************************************
const send_sms = (to_number, slug) => {
    const URL = APP_API_SMS;
    // const URL = "http://localhost:8003/api/sms/"
    // console.log("Sending to: " + URL);
    // console.log(to_number);
    return fetch(URL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            to: to_number,
            link: slug,
            site: "www.antibodytesting.com"
        }),
    }).then(checkStatus)
    .then(parseJSON)
        .catch((error) => {
            console.log(error);
            return null;
        });
}




export {
    getCurrentPositionUsingGoogleMapsAPI,
    getGoogleMapsAPIAccessToken,
    getAddressUsingCoordinates,
    getAddressUsingZIP,
    getClinicsFromZIP,
    getClinicsNearUserLocation,
    getDistanceFromCurrentLocation,
    getTimeByLocation,
    getNearestClinics,
    getPractice,
    getClinicsNearZIPLocation,
    send_sms,


    getClinicsNearZIPLocationV2,
    getDistanceFromCurrentLocationV2,
    getPracticeV2,
};