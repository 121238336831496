import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import Alert from './home/Alert'
import FindNearest from './home/FindNearest'
import CompareSection from './home/CompareSection'
import InTheKnowSection from './home/InTheKnowSection';



function Home(props){
    

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.clinics.length > 0){
            props.initialState();
        }
    }, [])

    function findDisplay(){
        if (props.loading) {
            return (
                <div className="home d-flex justify-content-center">
                        <i className="fa fa-spinner loading-icon align-self-center" style={{color:"#FF4A30" }} aria-hidden="true"></i>
                        {/* <p>Loading...</p> */}
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation and COVID or Antibody Testing - Home</title>
                        <meta name="description" content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available." />
                        <meta name="keywords" content="antibody testing, covid test, antibody testing near me, covid test near me" />
                    </Helmet>
                    <FindNearest setFilterSettings={props.setFilterSettings}
                        filterSettings={props.filterSettings}
                        userLocation={props.userLocation}
                        clinics={props.clinics}
                        setClinics={props.setClinics} />
                    <CompareSection />
                    <InTheKnowSection />
                </React.Fragment>
            )
        }
      }
    return(
        <React.Fragment>
            <Alert  setFilterSettings={props.setFilterSettings}
                    filterSettings={props.filterSettings} />
            {findDisplay()}
            
        </React.Fragment>
    );
}

export default Home;
