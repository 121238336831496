import React, { useEffect } from 'react';
import jsonData from './jsonData';
import Helmet from 'react-helmet';

function Privacy(){
    let data = jsonData.privacy_policy;
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    let information_collected_display = data.information_collected.map((info)=>{
        return(
            <li key={info.id}>
                <p><span className="list-title">{info.title}</span>{info.content}</p> 
            </li>
        )
    })

    let info_usage_display = data.info_usage_list.map((info)=>{
        return(
            <li key={information_collected_display.id}>
                <h5>{info.title}</h5>
                <p>{info.content}</p>
            </li>
        )
    })

    let paragraph_display = data.paragraph_list.map((info)=>{
        return(
            <React.Fragment>
                <h5 className="title-orange" key={information_collected_display.id}>
                    {info.title}
                </h5>
                <p>{info.content}</p>
            </React.Fragment>
            
        )
    })
    return(
        <React.Fragment>
            <Helmet>
                <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation and COVID or Antibody Testing - Privacy Policy</title>
                <meta name="description" content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available." />
                <meta name="keywords" content="antibody testing, covid test, antibody testing near me, covid test near me" />
            </Helmet>
            <div className="privacy-policy-banner p-5 mx-auto">
                <h1 className="text-center">
                    <i class="fas fa-lock"></i>
                </h1>
                <h1 className="text-center">Privacy Policy</h1>
            </div>
            <div className="container privacy-body p-5 text-justify">
                <h2>{data.title}</h2>
                <div className="separator-line-privacy my-4"></div>
                <p>{data.paragraph}</p>
                <h5 className="title-orange">{data.information_collected_title}</h5>
                <ul>{information_collected_display}</ul>

                <h5 className="title-orange">{data.cookies_title}</h5>
                <p>{data.cookies_content}</p>

                <h5 className="title-orange">{data.info_usage_title}</h5>
                <p>{data.info_usage_content}</p>

                <ul>
                    {info_usage_display}
                </ul>

                {paragraph_display}

                <h5 className="title-orange">{data.contact.title}</h5>
                <p>{data.contact.description}</p>
                <p><a href={"mailto:"+data.contact.email}>{data.contact.email}</a></p>
                <p className="m-0">{data.contact.address}</p>
                <p className="m-0">{data.contact.address_2}</p>
                <p className="m-0">{data.contact.address_3}</p>


            </div>
        </React.Fragment>
    )

}

export default Privacy;