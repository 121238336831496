import React from 'react';
import ModalMap from "./ModalMap";
import SMS from "./SMS";
import MapSent from "./MapSent";

const ModalSMS = ({address, lat, lng, mobileNumber, slug}) => {
    
    const add = address.address
    const city = address.city
    const state = address.state
    const zip = address.zip

        return(
            <>
                <div className="modal fade  modal-custom" id="sms-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Send this Map to SMS</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="map-responsive-modal  mb-3 rounded-10">
                                    <ModalMap lat={lat} lng={lng}/>   
                                </div>
                            
                                <div className="text-left"><i className="fas fa-map-marker-alt mr-3" aria-hidden="true"></i>{add} {city} {state} {zip}</div>
            
                                <>
                                    <SMS slug={slug}/>
                                </>
                                
                            </div>
                        </div>
                            
                    </div>
                </div>
                                <MapSent/>
            </>
        );
    
}

export default ModalSMS;