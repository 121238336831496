import React from 'react';
import { Link } from 'react-router-dom';
import jsonData from '../jsonData';

function Alert(props){
    let message = jsonData.alert_message

    function learnMoreClickHandler(){
        props.setFilterSettings({...props.filterSettings, 
            covidTest: true,
            antibodyTest: false,
        });
    }
    return(
        <div className="alert align-middle" role="alert">
            {message}<Link to="/search" className="mx-3" onClick={()=>{learnMoreClickHandler()}}>Learn More <i className="fas fa-arrow-right ml-3"></i></Link>
        </div>
    );
}

export default Alert;