import React from 'react';
import {days, get12HourFormatTimeString, isStillOpen} from "../../Helpers";

const ScheduleRow = ({day, hours}) => {
    if (!hours) return renderClosedRow(day);
    if (isScheduleToday(day)) return renderWeekdayOpenTodayRow(day, hours);
    return renderWeekdayOpenRow(day, hours);
}

const renderClosedRow = (day) => {
    return (
        <tr>
            <th scope="row">{days[day]}</th>
            <td colSpan="2" className="closed">Closed</td>
        </tr>
    )
}

const renderWeekdayOpenTodayRow = (day, hours) => {
    const openingHour = hours.opening;
    const closingHour = hours.closing;

    // check if within schedule
    const status = isStillOpen(hours);
    return (
        <tr className="today">
            <th scope="row">{days[day]}</th>
            <td>{`${get12HourFormatTimeString(openingHour)} - ${get12HourFormatTimeString(closingHour)}`}</td>
            <td>{`${status.status}${status.message != "" ? " - " + status.message : ""}`}</td>
        </tr>
    )
}

const isScheduleToday = (day) => {
    const now = new Date();
    return day == days[now.getDay()];
}

const renderWeekdayOpenRow = (day, hours) => {
    const openingHour = hours.opening;
    const closingHour = hours.closing;

    return (
        <tr>
            <th scope="row">{days[day]}</th>
            <td colSpan="2" >{`${get12HourFormatTimeString(openingHour)} - ${get12HourFormatTimeString(closingHour)}`}</td>
        </tr>
    )
}

export default ScheduleRow;