import React, { useEffect } from 'react';
import jsonData from './jsonData';
import Helmet from 'react-helmet';

function Terms(){
    let data = jsonData.terms_of_use;
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    let paragraph_display = data.paragraph_list.map((info) => {
        if(info.id === 5){
            let content_display = info.content_list.map((content) => {
                return(
                    <li key={content.id}>
                        {content.content}
                    </li>
                )
            })

            return(
            <React.Fragment>
                <h5 className="title-orange" key={info.id}>{info.title}</h5>
                <p>{info.content}</p>
                <ul>
                    {content_display}
                </ul>
            </React.Fragment>
            )
        } else{
            return(
                <React.Fragment>
                    <h5 className="title-orange" key={info.id}>{info.title}</h5>
                    <p>{info.content}</p>
                </React.Fragment>
    
            )

        }
     
    })
    return(
        <React.Fragment>
            <Helmet>
                <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation and COVID or Antibody Testing - Terms of Use</title>
                <meta name="description" content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available." />
                <meta name="keywords" content="antibody testing, covid test, antibody testing near me, covid test near me" />
            </Helmet>
            <div className="privacy-policy-banner p-5 mx-auto">
                <h1 className="text-center">
                    <i class="fas fa-pen"></i>
                </h1>
                <h1 className="text-center">{data.title}</h1>
            </div>
            <div className="container privacy-body p-5 text-justify">
                <h2>{data.title}</h2>
                <div className="separator-line-privacy my-4"></div>
                <p>{data.paragraph_1}</p>
                <p>{data.paragraph_2}</p>

                {paragraph_display}

                <p>{data.questions_paragraph}</p>
                
            </div>

        </React.Fragment>
    )

}

export default Terms;