import React from 'react';
import {
    days,
    months,
    get12HourFormatTimeString
} from "../Helpers";

const DateTodaySection = () => {
    const dateToday = new Date();
    const dateString = `${days[dateToday.getDay()]}, ${months[dateToday.getMonth()]} ` +
        `${dateToday.getDate()}, ${dateToday.getFullYear()}, ` +
        `${get12HourFormatTimeString(dateToday.getHours() + (dateToday.getMinutes() / 60))}`;

    return (
        <React.Fragment>
            <img className="mr-3" src="../../img/ico/calendar.svg" alt="calendar" />
            <div className="media-body">
                Today is
                    <h5 className="mt-0">{dateString}</h5>
            </div>

        </React.Fragment>


    )
}

export default DateTodaySection;