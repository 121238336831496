import React from 'react';

const TestIcon = ({src, title}) => {
    return (
        <div className="col-6 col-md circle-ico mb-4 mb-md-2 text-center px-md-1">
            <img src={src} alt={title} className="img-fluid mb-2 mb-md-3"/>
            <p className="">{title}</p>
        </div>
    )
}

export default TestIcon;