import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import jsonData from './jsonData'

function AboutUs(){
    let about_us = jsonData.about_us;

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <React.Fragment>
            <Helmet>
                <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation and COVID or Antibody Testing - About Us</title>
                <meta name="description" content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available." />
                <meta name="keywords" content="antibody testing, covid test, antibody testing near me, covid test near me" />
            </Helmet>
            <div className="section text-center grad-bg">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1 className="mb-3">About Us</h1>
                            <p>{about_us.main}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="section who-are-we ">
                <div className="container text-left">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-4 col-md-0 animated fadeIn">
                            <h3 className="mb-5">{about_us.who_section.title}</h3>
                            <div className="separator-line"></div>
                            <p>{about_us.who_section.p1}</p>
                            <p>{about_us.who_section.p1}</p>
                        </div>
                        <div className="col-md-6 offset-lg-1 col-lg-5">
                            <div className="box br-10 p-5 p-md-4 p-lg-5 animated fadeInUp ">
                                <img src={("../img/ico/" + about_us.how_section.image)} className="align-self-center mb-5" alt="..."/>
                                <h4 className="mb-4">{about_us.how_section.title}</h4>
                                <p>{about_us.how_section.p1}</p>
                                <p>{about_us.how_section.p2}</p>
                                <a href="#" className="mt-5 d-block">Read More <i className="fas fa-arrow-right ml-3" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" style={{backgroundColor:'#F6F8FA'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <img src={("../img/" + about_us.image)} className="img-fluid br-10" />
                        </div>
                        <div className="col-12 my-4">
                            <hr style={{borderTop:'1px solid #FF8421'}}/>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <p className="lead-violet text-left">
                                <strong>We respect</strong> that your time is valuable! <strong>We strive</strong> to keep our databases up to date to ensure you have the <strong>best experience</strong> possible
                            </p>
                        </div>
                    </div>
                    <div className="row text-left">
                        <div className="col-md-6">
                            <p>{about_us.first_column.p1}</p>
                            <p>{about_us.first_column.p2}</p>
                            <p>{about_us.first_column.p3}</p>
                        </div>
                        <div className="col-md-6">
                            <p>{about_us.second_column.p1}</p>
                            <p>{about_us.second_column.p2}</p>
                            <p>{about_us.second_column.p3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AboutUs