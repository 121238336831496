import React, { useState, useEffect } from 'react';
import DateTodaySection from '../DateTodaySection';
import FilterSection from './FilterSection';
import ClinicCard from './ClinicCard';


function ClinicContainer(props){
    let [page, setPage] = useState(props.page);
    let [currentPagination, setCurrentPagination] = useState(props.paginationPage);


    useEffect(()=>{
        setPage(props.page)
        setCurrentPagination(props.paginationPage)
    },[props.page]);

    function getIndexClickHandler(index, id){
        props.isActiveClickHandler(id)
        // if(index < 3){
        //     setPage(1)
        // } else {
        //     let number = Math.ceil((index + 1) / 3)
        //     setPage(number)
        // }
    }
    // props.clinics.sort((a, b) => (a.isActive < b.isActive) ? 1 : -1)
    let resultsDisplay = props.clinics.map((clinic, index)=>{
        return(
            <ClinicCard key={clinic.id}
                        id={clinic.practice_id}
                        clinic = {clinic}
                        name={clinic.name}
                        city={`${clinic.city}, ${clinic.state}`}
                        ratings={5}
                        premier={false}
                        userLocation={props.userLocation}
                        clinicDetails = {props.clinicDetails}
                        setClinicDetails = {props.setClinicDetails}
                        isActiveClickHandler={props.isActiveClickHandler}
                        getIndexClickHandler={getIndexClickHandler}
                        index={index}
                        />
        );
    })
   

    let pageNumbers = [];
    let clinicsPerPage = 3;
    let paginated = resultsDisplay;

    if (resultsDisplay.length > clinicsPerPage) {
        const start = 3 * (page - 1);
        const end = start + clinicsPerPage;
        paginated = resultsDisplay.slice(start, end);
    }

    let handlePreviousPageClick = () => {
        if(page === 1){
            setPage(1)
        } else {
            setPage(page - 1)
        }
    }
    
    let handleNextPageClick = () => {
            setPage(page + 1)
    }

    for (let i = 1; i <= Math.ceil(resultsDisplay.length / clinicsPerPage); i++) {
        pageNumbers.push(i);
    };

    let pageDisplay = pageNumbers.map(function(number){
    	return  (<li className={"btn pagination-item " + (number === page ? "active-item" : "")}
	              key={number}
	              onClick={function(){changePageClickHandler(number)}}
            	>
              		<span className="">{number}</span>
            	</li>)
    });

    function changePageClickHandler(number){
		setPage(number);
    }

    let paginationNumbers = []
    
    for (let i = 1; i <= Math.ceil(pageNumbers.length / 7); i++) {
        paginationNumbers.push(i);
    };

    

    let paginatedPagination = pageDisplay;
    
    if (pageDisplay.length > 7) {
        const start = 7 * (currentPagination - 1);
        const end = start + 7;
        paginatedPagination = pageDisplay.slice(start, end);
    }

    let handlePreviousPaginationClick = () => {
        if(currentPagination === 1){
            setCurrentPagination(1)
        } else {
            setCurrentPagination(currentPagination - 1)
        }
    }
    
    let handleNextPaginationClick = () => {
        setCurrentPagination(currentPagination + 1)
    }

    return(
        <div className="col-md-6">
            <div className="media mb-5 pt-5 text-left">
                <DateTodaySection />
            </div>
            <FilterSection    filterSettings = {props.filterSettings}
                        setFilterSettings = {props.setFilterSettings}
                        zipOnClickHandler = {props.zipOnClickHandler}
                        zipOnChangeHandler = {props.zipOnChangeHandler}
                        userInputZip = {props.userInputZip}
                        clinics = {props.clinics}
                        setClinics = {props.setClinics}
                        setUserAddress={props.setUserAddress}
                        setUserLocation = {props.setUserLocation}
                        userLocation = {props.userLocation}                        
                        userAddress = {props.userAddress}
                        isLoading = {props.isLoading}
                        setIsLoading={props.setIsLoading}
                        loading = {props.loading}
                        setLoading = {props.setLoading}
                        filterClinics = {props.filterClinics}
                        setFilteredClinics = {props.setFilteredClinics}
                        setRadius = {props.setRadius}
                        radius = {props.radius}
                        covidClinics = {props.covidClinics}
                        antiBodyClinics = {props.antiBodyClinics}
                        covidAndAntibodyClinics = {props.covidAndAntibodyClinics}
                        message = {props.message}
                        setMessage = {props.setMessage}
                        errorDisplay = {props.errorDisplay}
                        error={props.error}
                        setNearestClinicsFlagZipBased = {props.setNearestClinicsFlagZipBased}
                        nearestClinicsFlagZipBased = {props.nearestClinicsFlagZipBased}
                        nearestClinicsFlagRadiusBased = {props.nearestClinicsFlagRadiusBased}
                        setNearestClinicsFlagRadiusBased = {props.setNearestClinicsFlagRadiusBased}/>
            { paginated }
            { resultsDisplay.length > 3 &&
                <div className="react pager">
                    <button className="pagination-button mb-5 react prev-button"
                        onClick={handlePreviousPageClick}
                        disabled={page <= 1}
                    ><i className="fa fa-chevron-left" aria-hidden="true"></i> Previous</button>
                    
                        <div className="pagination-container react paginator">
                            
                        {pageDisplay.length > 7 &&
                            <button className="btn react prev-button pagination-arrow" disabled={currentPagination <= 1} onClick={handlePreviousPaginationClick}>
                                <i className="fa fa-chevron-left" aria-hidden="true" ></i>
                            </button>}

                            {paginatedPagination}
                    {pageDisplay.length > 7 &&
                            <button className="btn react next-button pagination-arrow" disabled={currentPagination === Math.ceil(pageDisplay.length / 7)} onClick={handleNextPaginationClick}>
                                <i className="fa fa-chevron-right" aria-hidden="true" ></i>
                            </button> }
                        </div>
                   
                    <button className="pagination-button mb-5 react next-button"
                        onClick={handleNextPageClick}
                        disabled={page === Math.ceil(resultsDisplay.length / 3)}
                    >Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                </div>
            }

        </div>

    );
}

export default ClinicContainer;