import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Helmet from 'react-helmet';

import DateTodaySection from './DateTodaySection';
import ScheduleTable from './listings/ScheduleTable';
import SingleLocationMapSection from './listings/SingleLocationMapSection';
import TestingInfoCard from './listings/TestingInfoCard';
import ServicesOffered from './listings/ServicesOffered';
import Insurance from './listings/Insurance';
import About from './listings/About';
import ModalSMS from './listings/ModalSMS';
import {getCurrentPositionUsingGoogleMapsAPI, getPractice, getPracticeV2, getTimeByLocation} from "../Client";
import {getRandomImageFilename} from "../Helpers";
import '../App.css';

class Listings extends React.Component {
    state = {
        clinic: {
            id: 0,
            practice_id: "",
            longitude: 121,
            latitude: 15,
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            email: "",
            about: "",
            services: "",
            website: "Not available",
            covid19TestServices: [],
            antibodyTestServices: [],
            schedules: {
                Monday: null,
                Tuesday: null,
                Wednesday: null,
                Thursday: null,
                Friday: null,
                Saturday: null,
                Sunday: null,
            },
            practices: [],
            insurance: "",
        },
        userLocation: {
            lat: 15,
            lng: 121
        },
        practiceUTCOffset: 0,
        userUTCOffset: 0,
        practiceDSTUTCOffset: 0,
        userDSTUTCOffset: 0,
        redirect: false,
        loading: false
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0)

        this.setState({
            loading: true,
            goBackButton: false
        })

        if (window.history.state === null) {
            this.setState({
                goBackButton: false
            })
        } else {
            this.setState({
                goBackButton: true
            })
        }
        const id = this.props.match.params.slug.split("-")[0];

        let clickedClinic = await getPracticeV2(id)

        if (clickedClinic === null) {
            this.setState({
                loading: false,
                redirect: true
            })
        } else {
            let userLocation = await getCurrentPositionUsingGoogleMapsAPI();
            let today = await Date.now() / 1000;
            let userDetails = await getTimeByLocation(userLocation.location, today);
            let practiceDetails = await getTimeByLocation({
                lat: clickedClinic.latitude,
                lng: clickedClinic.longitude
            }, today);
            this.setState({
                clinic: clickedClinic,
                userLocation: userLocation,
                practiceUTCOffset: practiceDetails.rawOffset,
                userUTCOffset: userDetails.rawOffset,
                practiceDSTUTCOffset: practiceDetails.dstOffset,
                userDSTUTCOffset: userDetails.dstOffset
            })


            this.setState({
                loading: false,
                redirect: false
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to='/404'/>
            )
        }
        const practiceInfo = this.state.clinic;
        const address = `${practiceInfo.address}, ${practiceInfo.city}, ${practiceInfo.state} ${practiceInfo.zip}`
        const deltaUTCOffset = this.state.userUTCOffset - this.state.practiceUTCOffset;
        let website = "";
        if (practiceInfo.website !== "") {
            const website_pattern = /^https?:\/\//;
            website = website_pattern.test(practiceInfo.website) ? practiceInfo.website :
                "https://" + practiceInfo.website;
        }

        // head variables
        const title = `${practiceInfo.name} ${practiceInfo.city} ${practiceInfo.state} Antibody Testing - AntibodyTesting.com`;
        const description = `Information for ${practiceInfo.name} ${practiceInfo.city} ${practiceInfo.state} Antibody Testing - Hours, Location, Insurance Accepted, COVID-19 Testing Service Information`;
        const keywords = `${practiceInfo.name}, antibody testing, ${practiceInfo.city}, ${practiceInfo.state}`;


        return (
            <React.Fragment>
                {this.state.loading &&
                <div className='loading-overlay'>
                    <div className='message'>
                        <i className="fa fa-spinner loading-icon" aria-hidden="true"></i>
                        <p>Loading...</p>
                    </div>
                </div>
                }
                <div className="">
                    <Helmet>
                        <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation
                            and COVID or Antibody Testing - Clinic</title>
                        <meta name="description"
                              content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available."/>
                        <meta name="keywords"
                              content="antibody testing, covid test, antibody testing near me, covid test near me"/>
                    </Helmet>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 text-left">
                                {this.state.goBackButton &&
                                <div className="col-6 col-md-3">
                                    <Link to={{
                                        pathname: '/results/',
                                        zip: practiceInfo.zip,
                                        zipOnly: this.props.zipOnly
                                    }}>
                                        <div id="backbtn" className="pt-5">
                                            <i className="fas fa-arrow-left mr-3"></i> Back to Results Page
                                        </div>
                                    </Link>
                                </div>
                                }
                                <div className="media pt-5 mb-5 text-left">
                                    <DateTodaySection/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="box br-10 border p-4 p-md-5 mb-5 shadow">
                                    <div className="row no-gutters">
                                        <div className="pr-2 col-12 col-md-7 col-lg-6 text-left">
                                            <div className="row">
                                                <div
                                                    className="float-none float-md-left text-left d-block d-md-inline col-4">
                                                    <img src={getRandomImageFilename()} className="img-fluid rounded"
                                                         alt="clinic"/>
                                                </div>
                                                <div className="col-8">
                                                    <ul className="list-unstyled" id="clinic-details-list">
                                                        <li>
                                                            <h5 className="mb-3">{this.state.clinic.name}</h5>
                                                        </li>
                                                        <li className="mb-3">
                                                            <i className="fas fa-map-marker-alt mr-3"></i>{address}
                                                        </li>
                                                        <li className="mb-0 mb-md-3">
                                                            <a href="#"
                                                               className="d-inline-block py-2 px-3 mb-3 mb-md-0 border br-10 "
                                                               data-toggle="modal"
                                                               data-target="#sms-modal">
                                                                <i
                                                                    className="fas fa-envelope mr-3"></i>SMS Address</a>
                                                        </li>

                                                        <li className="mb-3"><i
                                                            className="fas fa-phone-alt mr-3"></i>{practiceInfo.phone}
                                                        </li>

                                                        <li className="mb-3 mail">
                                                            <p className="company-link"><i
                                                                className="fas fa-globe-americas mr-3"></i><a
                                                                href={website} target="_blank"
                                                                rel="noreferrer">{practiceInfo.website === "" ? "Not available" : practiceInfo.website}
                                                            </a></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <hr className="mb-3"/>

                                            <ScheduleTable schedule={this.state.clinic.schedules}
                                                           userUTCOffset={this.state.userUTCOffset}
                                                           practiceUTCOffset={this.state.practiceUTCOffset}
                                                           DSTUTCOffset={this.state.practiceDSTUTCOffset}
                                                           userDSTUTCOffset={this.state.userDSTUTCOffset}/>

                                        </div>
                                        <div className="col-md-12 col-lg-6 pl-lg-3">
                                            {/* <div class="row no-gutters">
                                            <div class="col">
                                                <div class="float-left">
                                                    <i class="fas fa-star mr-3" aria-hidden="true" style={{ color: "#FFAC37" }}></i>5.0 (345)
                                                </div>
                                                <img src="../../img/ico/premier-l.svg" class="img-fluid float-right mb-4" />
                                            </div>
                                        </div> */}
                                            <div class="row no-gutters">
                                                <div class="col">
                                                    <div class="map-responsive vh-100 mb-5 br-10"
                                                         style={{maxHeight: "662px", minHeight: "300px"}}>
                                                        <SingleLocationMapSection lat={practiceInfo.latitude}
                                                                                  lng={practiceInfo.longitude}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="row mt-4 text-left">
                                        <TestingInfoCard title="COVID-19 Viral Testing"
                                                         description="This test looks for an active infection of
                                                                    SARS-CoV-2, or whether you are currently sick with Coronavirus. The method of testing varies by
                                                                    Urgent Care location."
                                                         tests={this.state.clinic.covid19TestServices}/>
                                        <TestingInfoCard title="COVID-19 Antibody Testing"
                                                         description="This test looks for a past infection with SARS-CoV-2, which indicates that you have been sick but
                                                                    recovered from Coronavirus. A past exposure with positive antibodies can possibly protect against
                                                                    future infections, although this information is not fully validated."
                                                         tests={this.state.clinic.antibodyTestServices}/>
                                    </div>
                                    <div className="row mt-2 mt-md-5 text-left">
                                        <ServicesOffered title="Services We Offer"
                                                         services={this.state.clinic}/>

                                        <Insurance title="Insurance Carriers Accepted"
                                                   insuranceCompany={this.state.clinic.insurance}/>

                                    </div>
                                    <div className="row mt-5">
                                        <About title="About Us"
                                               about={this.state.clinic}/>
                                    </div>

                                </div>
                                {/* <div>
                                Gallery
                            </div> */}
                                <ModalSMS
                                    address={this.state.clinic}
                                    lat={practiceInfo.latitude}
                                    lng={practiceInfo.longitude}
                                    slug={this.props.match.params.slug}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default Listings

