import React, { useEffect, useState } from 'react';
import ClinicContainer from './results/ClinicContainer';
import GoogleMapContainer from './GoogleMapContainer';
import { isMobile, isBrowser } from "react-device-detect";

import {  getAddressUsingZIP, getClinicsNearZIPLocation, getNearestClinics } from "../Client";

function Results(props){
    let abortController = new AbortController();

    let [userInputZip, setUserInputZip] = useState();
    let [errorMessage, setErrorMessage] = useState("");
    let [error, setError] = useState(false);
    let [activeMarker, setActiveMarker] = useState({})
    let [displayedClinics, setDisplayedClinics] = useState([]);
    let [clinicsWithActive, setClinicsWithActive] = useState([]);
    // let [resultAddress, setResultAddress] = useState(props.userAddress);
    // let [resultMessage, setResultMessage] = useState(props.message)
    let [isLoading, setIsLoading] = useState(false);
    let [page, setPage] = useState(1);
    let [paginationPage, setPaginationPage] = useState(1);
   
    let covidClinics = props.clinics.filter((clinic) => clinic.covid19TestServices.length !== 0);
    let antiBodyClinics = props.clinics.filter((clinic) => clinic.antibodyTestServices.length !== 0);
    let covidAndAntibodyClinics = props.clinics.filter((clinic) => clinic.covid19TestServices.length !== 0 || clinic.antibodyTestServices.length !== 0);
    
    function zipOnChangeHandler(e){
        let value = e.target.value;
            setUserInputZip(value)
        
    }

    function mapStyleSwitch(){
        if(isBrowser){
            return( "map-desktop")
        }else if (isMobile){
            return( "map-mobile")
        }
    }

    let onFetchZIPAddressResult = (data) => {
        if (data) {
            if (data.results.length > 0) {
                if (data.results[0].types && data.results[0].types[0] === "postal_code") {
                    const address = data.results[0].formatted_address;
                    return {
                        address: address,
                        coordinates: {
                            lat: data.results[0].geometry.location.lat,
                            lng: data.results[0].geometry.location.lng
                        }
                    };
                }
                else {
                    return {
                        address: "Invalid ZIP code",
                        coordinates: {
                            lat: props.userLocation.lat,
                            lng: props.userLocation.lng
                        }
                    };
                }
            }

            return null;
        }

        return null;
    }
    async function getAddress(zip){
        setIsLoading(true)
        localStorage.setItem('userZip', zip);
        return getAddressUsingZIP(zip).then(
            function(data){ 
                let addressInformation = onFetchZIPAddressResult(data)
                setIsLoading(false)
                return addressInformation 
            }
        )
    }
    
    async function fetchClinics(addressInformation){
        setIsLoading(true)
        let params = {
            lat: addressInformation.coordinates.lat,
            long: addressInformation.coordinates.lng,
            radius: props.radius,
        }
        return getClinicsNearZIPLocation(params, abortController).then(
            function(clinics){
                // show nearest clinics if there are no clinics available in the area
                if (clinics.length < 1) {
                    getNearestClinics({ lat: addressInformation.coordinates.lat, long: addressInformation.coordinates.lng }, abortController).then(
                      function (res) {
                        props.setUserLocation({
                            lat: addressInformation.coordinates.lat,
                            lng: addressInformation.coordinates.lng
                        })
                        props.setClinics(res)
                        props.setNearestClinicsFlagRadiusBased(false)
                        props.setNearestClinicsFlagZipBased(true)
                        // props.setMessage(`There are no testing centers from the zip code you have entered. Showing you ${res.length} nearest testing center${res.length > 1 ? "s" : ""}.`);
                        setIsLoading(false)                    
                    })

                } else {
                    props.setUserLocation({
                        lat: addressInformation.coordinates.lat,
                        lng: addressInformation.coordinates.lng
                    })
                    props.setClinics(clinics)
                    props.setNearestClinicsFlagRadiusBased(false)
                    props.setNearestClinicsFlagZipBased(false)
                    // props.setMessage(`There are ${clinics.length} testing center${clinics.length > 1 ? "s" : ""} near you.`)
                    setIsLoading(false)
                    return clinics
                } 
            }
        )
    }


    function assignIsActive(){
        let assignIsActive = displayedClinics.map((clinic) => {
            let details = {"isActive": false}
            Object.assign(clinic, details)
            return clinic
        });
        setClinicsWithActive(assignIsActive)
    }

    function isActiveClickHandler(clinicId){
        let updatedClinics = displayedClinics.map((clinic, index) => {
            
            if(clinic.practice_id === clinicId){
                let pageNumbers = [];
                for (let i = 1; i <= Math.ceil(displayedClinics.length / 3); i++) {
                    pageNumbers.push(i);
                };
                let number;
                if(index > 2){
                    number = Math.ceil((index + 1) / 3)
                } else {
                    number = 1
                }
                let paginationPage;
                if(number > 6){
                    paginationPage = Math.ceil((number + 1) / 7)
                } else {
                    paginationPage = 1
                }
                setPaginationPage(paginationPage)
                setPage(number);
                
                clinic.isActive = true 
                return clinic
            } else {
                clinic.isActive = false 
                return clinic
            }
            
        });
        setClinicsWithActive(updatedClinics)
        var element = document.getElementsByClassName("premier");
            if(element[0] !== undefined){
                let coords = element[0].offsetTop
                window.scrollTo(0, coords);
            }
           
    }

    function onMarkerClick(marker, map, e){
        setActiveMarker(marker)
    }
    function clearData(){
        setDisplayedClinics([]);
        localStorage.removeItem('userZip');
        document.getElementById('resultMessage').innerHTML = "";
        document.getElementById('resultAddress').innerHTML = "";
    }

    async function zipOnClickHandler() {
        if (userInputZip !== undefined) {
            if(userInputZip === ''){
                clearData()
                if(isMobile){
                    setErrorMessage("Please enter zip code")
                    setError(true)
                } else if(isBrowser){
                    props.fetchUserLocationAndClinics()
                }
               
            } else {
                let zip = userInputZip.replace(/^0+/, '');
                let addressInformation = await getAddress(zip);
                if(addressInformation === null || addressInformation.address === "Invalid ZIP code"){                                       
                    clearData()
                    setError(true)
                    setErrorMessage('Please enter a valid zip code.')
                } else { 
                    await fetchClinics(addressInformation);
                    props.setUserAddress(addressInformation.address)
                }
            }
        } else if (userInputZip === undefined){
            clearData()
            if(isMobile){
                setErrorMessage("Please enter zip code")
                setError(true)
            } else if(isBrowser){
                props.fetchUserLocationAndClinics()
            }
        }
        
    }

    function errorDisplay(){
        if(error){
            return(
                <p className="validation mx-auto mb-0 mt-2"><small style={{color: "red"}}>{errorMessage}</small></p>
                )
        } else{
            return
        }
    }


    // async function zipOnClickHandler() {
    
    //     console.log(userInputZip)
    //     async function fetchClinics(){
    //         props.setLoading(true)
    //         let zip = userInputZip.replace(/^0+/, '')
    //         let data = await getAddressUsingZIP(zip)
    //         let addressInformation = await onFetchZIPAddressResult(data);
    //         let address = addressInformation ? addressInformation.address : "Invalid ZIP code";
            
    //         let coordinates = addressInformation ? addressInformation.coordinates : {
    //             lat: null,
    //             lng: null,
    //         };
    //         props.setUserLocation({lat: coordinates.lat, lng: coordinates.lng})

    //         let params = {
    //             lat: coordinates.lat,
    //             long: coordinates.lng,
    //             radius: props.radius,
    //         }
    //         let clinics = await getClinicsNearZIPLocation(params, abortController);
    //         props.setClinics(clinics)
    //         props.setUserAddress(address)
    //         if(address === "Invalid ZIP code"){ 
    //             props.setMessage('Please enter a valid zip code.')
    //         } else {
    //             props.setMessage('')
    //         }
    //         props.setLoading(false)
    //     }

    //     if (userInputZip !== undefined) {
    //         fetchClinics();
    //         // props.setMessage('There are no clinics within a '+props.radius+ 'mi radius.');
    //         console.log(props.message)
            
    //     } else {
    //         props.fetchUserLocationAndClinics();
    //         props.setMessage('There are '+props.clinics.length+ ' clinics within a '+props.radius+ 'mi radius.');
    //         console.log(props.message)
    //     }


    // }
    

    function displayedClinicSelector(){
        if(props.filterSettings.covidTest && props.filterSettings.antibodyTest){
            console.log('both')
            setDisplayedClinics(covidAndAntibodyClinics);
            props.setMessage(`There ${covidAndAntibodyClinics.length === 1 ? "is" : "are"} ${covidAndAntibodyClinics.length} covid and antibody testing center${covidAndAntibodyClinics.length === 1 ? "" : "s"} near you. ${covidAndAntibodyClinics.length === 0 ? "Please adjust the filter settings to show clinics near you." : ""}`)
        } else if(props.filterSettings.covidTest && !props.filterSettings.antibodyTest) {
            console.log('covid')            
            setDisplayedClinics(covidClinics)
            props.setMessage(`There ${covidClinics.length === 1 ? "is" : "are"} ${covidClinics.length} covid testing center${covidClinics.length === 1 ? "" : "s"} near you. ${covidClinics.length === 0 ? "Please adjust the filter settings to show clinics near you." : ""}`)
        } else if(props.filterSettings.antibodyTest && !props.filterSettings.covidTest) {
            console.log('antibody')
            setDisplayedClinics(antiBodyClinics)
            props.setMessage(`There ${antiBodyClinics.length === 1 ? "is" : "are"} ${antiBodyClinics.length} antibody testing center${antiBodyClinics.length === 1 ? "" : "s"} near you. ${antiBodyClinics.length === 0 ? "Please adjust the filter settings to show clinics near you." : ""}`)
        } else {
            setDisplayedClinics(props.clinics)

            console.log(props.message, props.nearestClinicsFlagZipBased)
            if(!props.nearestClinicsFlagZipBased && !props.nearestClinicsFlagRadiusBased){
                props.setMessage(`There ${props.clinics.length === 1 ? "is" : "are"} ${props.clinics.length} testing center${props.clinics.length === 1 ? "" : "s"} near you.`)
            } else if(props.nearestClinicsFlagZipBased){
                props.setMessage(`There are no testing centers from the zip code you have entered. Showing you ${props.clinics.length} nearest testing center${props.clinics.length > 1 ? "s" : ""}.`);
            } else if(props.nearestClinicsFlagRadiusBased){
                props.setMessage(`There are no testing centers within ${props.radius}mi radius. Showing you ${props.clinics.length} nearest testing center${props.clinics.length > 1 ? "s" : ""}.`);
            }
            
            
        }
    }

    useEffect(()=>{
        displayedClinicSelector()
    },[props.filterSettings]);

    useEffect(()=>{
        assignIsActive()
    },[displayedClinics]);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            {isLoading &&
                <div className='loading-overlay'>
                    <div className='message m-1'>
                        <i className="fa fa-spinner loading-icon" aria-hidden="true"></i>
                        <p className="mx-auto pt-3 mb-0">Please wait for a moment...</p>
                    </div>
                </div>
            }
            {props.loading &&
                <div className='loading-overlay'>
                    <div className='message'>
                        <i className="fa fa-spinner loading-icon" aria-hidden="true"></i>
                        <p className="mx-auto pt-3 mb-0">Please wait for a moment...</p>
                    </div>
                </div>
            }
            <div className="results">
                <div className="container-fluid">
                    <div className="row">
                        <ClinicContainer filterSettings = {props.filterSettings}
                                setFilterSettings = {props.setFilterSettings}
                                zipOnClickHandler = {zipOnClickHandler}
                                zipOnChangeHandler = {zipOnChangeHandler}
                                userInputZip = {userInputZip}
                                clinics = {clinicsWithActive}
                                setClinics = {props.setClinics}
                                userLocation = {props.userLocation}
                                setUserLocation = {props.setUserLocation}
                                setUserAddress={props.setUserAddress}
                                userAddress = {props.userAddress}
                                clinicDetails = {props.clinicDetails}
                                setClinicDetails = {props.setClinicDetails}
                                isLoading = {isLoading}
                                setIsLoading={setIsLoading}
                                loading = {props.loading}
                                setLoading = {props.setLoading}
                                radius = {props.radius}
                                setRadius = {props.setRadius}
                                covidClinics = {covidClinics}
                                antiBodyClinics = {antiBodyClinics}
                                covidAndAntibodyClinics = {covidAndAntibodyClinics}
                                message = {props.message}
                                setMessage = {props.setMessage}
                                errorDisplay = {errorDisplay}
                                error={error}
                                setNearestClinicsFlagZipBased = {props.setNearestClinicsFlagZipBased}
                                nearestClinicsFlagZipBased = {props.nearestClinicsFlagZipBased}
                                nearestClinicsFlagRadiusBased = {props.nearestClinicsFlagRadiusBased}
                                setNearestClinicsFlagRadiusBased = {props.setNearestClinicsFlagRadiusBased}
                                isActiveClickHandler={isActiveClickHandler}
                                page = {page}
                                paginationPage = {paginationPage}
                                />

                        <div className="col-md-6 map-container">
                            <div className={"map-responsive " + (isBrowser ? "map-desktop" : "map-mobile")}>
                            <GoogleMapContainer locations={clinicsWithActive}
                                                lat={props.userLocation.lat}
                                                lng={props.userLocation.lng}
                                                isActiveClickHandler={isActiveClickHandler}
                                                activeMarker = {activeMarker}
                                                onMarkerClick={onMarkerClick}/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Results;