import React from 'react';
import { Link } from 'react-router-dom';
import {Map, GoogleApiWrapper, InfoWindow, Marker} from "google-maps-react";
import {getGoogleMapsAPIAccessToken} from "../Client.js";
import { isMobile, isBrowser } from "react-device-detect";

const mapStyles = [{
    width: '100%',
    height: '100%'
}];

class GoogleMapContainer extends React.Component {

    state = {
        center: {
            lat: 100,
            lng: 100,
        },
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        loading: false
    }
    componentDidMount() {
        const bounds = new window.google.maps.LatLngBounds();
        if(this.props.locations && this.props.locations.length > 0) {
            this.props.locations.forEach((clinic) => {
                bounds.extend(new window.google.maps.LatLng(clinic.latitude,
                                                            clinic.longitude));
            });

            // const practice = this.props.locations[this.props.locations.length - 1];
            // bounds.extend(new window.google.maps.LatLng(practice.practice.latitude,
            //     practice.practice.longitude));
        }
        bounds.extend(new window.google.maps.LatLng(this.props.lat, this.props.lng));

        // const boundaryCenter = bounds.getCenter();
        // this.refs.resultMap.map.setCenter(boundaryCenter);
        // this.refs.resultMap.map.panToBounds(bounds);

        // const size = {
        //     width: 640, // Map width in pixels
        //     height: 380, // Map height in pixels
        // };
        //
        // const newBounds = {
        //     ne: {
        //         lat: bounds.getNorthEast().lat(),
        //         lng: bounds.getNorthEast().lng(),
        //     },
        //     sw: {
        //         lat: bounds.getSouthWest().lat(),
        //         lng: bounds.getSouthWest().lng(),
        //     }
        // }
        // const ne = new window.google.maps.LatLng(newBounds.ne);
        // const sw = new window.google.maps.LatLng(newBounds.sw);
        // const finalBounds = new window.google.maps.LatLngBounds([ne, sw]);

        // map.fitBounds(bbox, {    padding: {top: 10, bottom:25, left: 15, right: 5}  });
        this.refs.resultMap.map.fitBounds(bounds, {
            top: 80,
            bottom: 80,
            right: 80,
            left: 80,
        });
        

    }

    componentDidUpdate() {
        const bounds = new window.google.maps.LatLngBounds();
        if(this.props.locations && this.props.locations.length > 0) {
            this.props.locations.forEach((clinic) => {
                bounds.extend(new window.google.maps.LatLng(clinic.latitude,
                                                            clinic.longitude));
            });
        }
        bounds.extend(new window.google.maps.LatLng(this.props.lat, this.props.lng));

        this.refs.resultMap.map.fitBounds(bounds, {
            top: 80,
            bottom: 80,
            right: 80,
            left: 80,
        });
    
    }

    onMarkerClick = (props, marker, map, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        })
       this.props.isActiveClickHandler(marker.clinic.practice_id);

    }

    onMapClicked = (props) => {
            this.setState({
                selectedPlace: {},
                activeMarker: {},
                showingInfoWindow: false,
            })
    };


 

    render()
    {
        let slug; 
        const icon = { url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png", scaledSize: { width: 45, height: 45 } };
        const markers = this.props.locations.map((clinic) => {
            if (clinic.isActive) {
                if (this.state.activeMarker.clinic !== undefined) {
                    if (this.state.activeMarker.clinic.practice_id !== clinic.practice_id) {
                        this.setState({
                            showingInfoWindow: false,
                            activeMarker: {}
                        })
                    }
                } 
                // else{
                //     this.setState({
                //         showingInfoWindow: true,
                //         activeMarker:{}
                //     })
                // }


                const clinicNameSlug = clinic.name.toLowerCase().replaceAll(' ', '-')
                    .replaceAll('.', '')
                    .replaceAll(',', '')
                    .replaceAll("'", '');
                const cityNameSlug = clinic.city.toLowerCase().replaceAll(' ', '-')
                    .replaceAll('.', '')
                    .replaceAll(',', '')
                    .replaceAll("'", '');
                const zipSlug = clinic.zip.padStart(5, '0');
                slug = `${clinic.practice_id}-${clinicNameSlug}-${cityNameSlug}-${zipSlug}`;
            

                return (
                        <Marker
                            position={{ lat: clinic.latitude, lng: clinic.longitude }}
                            title={clinic.name}
                            key={clinic.id}
                            name={clinic.name}
                            clinic={clinic.practice}
                            onClick={this.onMarkerClick}
                            icon={icon}
                        >
                        </Marker>
                )
            } else {
                return (
                    <Marker
                        position={{ lat: clinic.latitude, lng: clinic.longitude }}
                        title={clinic.name}
                        key={clinic.id}
                        name={clinic.name}
                        clinic={clinic.practice}
                        onClick={this.onMarkerClick}
                    >
                    </Marker>
                )
            }
        });
       
        
        // const markers = this.props.locations.map((clinic) => {
        //     if (this.state.activeMarker.clinic) {
        //         if (this.state.activeMarker.clinic.practice_id === clinic.practice.practice_id) {

        //             const clinicNameSlug = clinic.practice.name.toLowerCase().replaceAll(' ', '-')
        //                 .replaceAll('.', '')
        //                 .replaceAll(',', '')
        //                 .replaceAll("'", '');
        //             const cityNameSlug = clinic.practice.city.toLowerCase().replaceAll(' ', '-')
        //                 .replaceAll('.', '')
        //                 .replaceAll(',', '')
        //                 .replaceAll("'", '');
        //             const zipSlug = clinic.practice.zip.padStart(5, '0');
        //             slug = `${clinic.practice.practice_id}-${clinicNameSlug}-${cityNameSlug}-${zipSlug}`;

        //             return (
        //                 <Marker
        //                     position={{ lat: clinic.practice.latitude, lng: clinic.practice.longitude }}
        //                     title={clinic.practice.name}
        //                     key={clinic.practice.id}
        //                     name={clinic.practice.name}
        //                     clinic={clinic.practice}
        //                     onClick={this.onMarkerClick}
        //                     icon={icon}
        //                 >
        //                 </Marker>
        //             )
        //         } else {
        //             return (
        //                 <Marker
        //                     position={{ lat: clinic.practice.latitude, lng: clinic.practice.longitude }}
        //                     title={clinic.practice.name}
        //                     key={clinic.practice.id}
        //                     name={clinic.practice.name}
        //                     clinic={clinic.practice}
        //                     onClick={this.onMarkerClick} 
        //                 />
        //             )
        //         }
        //     } else {
        //         return (
        //             <Marker
        //                 position={{ lat: clinic.practice.latitude, lng: clinic.practice.longitude }}
        //                 title={clinic.practice.name}
        //                 key={clinic.practice.id}
        //                 name={clinic.practice.name}
        //                 clinic={clinic.practice}
        //                 onClick={this.onMarkerClick}
        //             />
        //         )
        //     }
        // });

        let centerLocation;
        // = {lat: this.props.lat, lng: this.props.lng}
        if(this.props.locations !== undefined) {
            if (this.props.locations.length === 1) {
                centerLocation = {
                    lat: this.props.locations[0].latitude,
                    lng: this.props.locations[0].longitude
                }
            } else {

                centerLocation = this.props.locations.length > 0 ? {
                    lat: this.props.locations[Math.floor(this.props.locations.length / 2)].latitude,
                    lng: this.props.locations[Math.floor(this.props.locations.length / 2)].longitude,
                } : {   lat: this.props.lat,
                        lng: this.props.lng
                    } 
            }
        } else {
            centerLocation = {
                lat: this.props.lat,
                lng: this.props.lng,
            }
        }
        const initialCenter = {
            lat: centerLocation.lat,
            lng: centerLocation.lng}
        //     lat: this.props.lat,
        //     lng: this.props.lng,
        // }
        
       

        return(
            <React.Fragment>

                <Map
                    google={this.props.google}
                    ref="resultMap"
                    styles={mapStyles}
                    zoom={10}
                    onClick={this.onMapClicked}
                >
                    {/* {isBrowser &&
                    <Marker
                        position={{lat: this.props.lat, lng: this.props.lng}}
                        title="You are here."
                        name='You are here.'
                        onClick={this.onMarkerClick}
                        icon={ {url: '/img/youarehere.png', scaledSize: { width: 32, height: 32}}}
                    /> } */}
                    
                    {markers}
                    <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}>
                            <div> 
                                <a href={`/clinic/${slug}`} className="map-popup" target="_blank" rel="noreferrer">{this.state.activeMarker.clinic === undefined ? "": this.state.activeMarker.clinic.name}</a>
                            </div>
                    </InfoWindow>
              

               
                </Map>

            </React.Fragment>
            
        );
    }
}

export default GoogleApiWrapper({
    apiKey: getGoogleMapsAPIAccessToken(),
})(GoogleMapContainer);