import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {days, getRandomImageFilename, getTimeDecimalEquivalent, isStillOpen} from "../../Helpers";
import {getDistanceFromCurrentLocation, getDistanceFromCurrentLocationV2, getTimeByLocation} from "../../Client";

function ClinicCard(props){
    let [state, setState] = useState({
        distance: 0,
        practiceUTCOffset: 0,
        userUTCOffset: 0,
        practiceDSTUTCOffset: 0,
        userDSTUTCOffset: 0,
    });

    useEffect( ()  =>  {
        const data = async () => {
            return await fetchTimeByLocation()
          };
          return data();
    },[])

    let fetchTimeByLocation = async () => {
        let today = await Date.now() / 1000;
        let data = await getTimeByLocation(props.userLocation, today)   
        let distanceUserData = await getDistanceFromCurrentLocationV2(props.userLocation, props.clinic)
        let distanceClinicData = await getTimeByLocation({lat: props.clinic.latitude, lng: props.clinic.longitude}, today)

        return await setState({
            ...state, 
            distance: Math.ceil(distanceUserData.distance.mi),
            userUTCOffset: data.rawOffset,
            userDSTUTCOffset: data.dstOffset,
            practiceUTCOffset: distanceClinicData.rawOffset,
            practiceDSTUTCOffset: distanceClinicData.dstOffset
        });
    };

    // function viewDetailsClickHandler(){
    //     props.setClinicDetails({
    //         clinic: props.clinic,
    //         state: state
    //     }) 
    // };


    const today = days[(new Date()).getDay()];
    const day = props.clinic.schedules[today];

    const practice_opening = day ? getTimeDecimalEquivalent(day.opening) : null;
    const practice_closing = day ? getTimeDecimalEquivalent(day.closing) : null;

    const deltaUTCOffset = ((((state.userUTCOffset + state.userDSTUTCOffset)
        - (state.practiceUTCOffset + state.practiceDSTUTCOffset))) / 60) / 60;

    const user_opening = practice_opening ? practice_opening + deltaUTCOffset : null;
    const user_closing = practice_closing ? practice_closing + deltaUTCOffset : null;

    const status = isStillOpen(user_opening ? { opening: user_opening, closing: user_closing } : null);

    const isPremier = props.premier ? "premier" : "";
    const isActive = props.clinic.isActive ? "premier" : "";
  
  
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars = i < props.ratings ? stars.concat(
            <i className="fas fa-star rated"></i>
        ) : stars.concat(
            <i className="fas fa-star"></i>
        );
    }

    const distance = state.distance != null ? (state.distance < 1 ? "<1 mi away" :
        state.distance + " mi away") : "";
    const imageFile = getRandomImageFilename();

    const clinicNameSlug = props.name.toLowerCase().replaceAll(' ', '-')
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll("'", '');
    const cityNameSlug = props.city.toLowerCase().replaceAll(' ', '-')
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll("'", '');
    const zipSlug = props.clinic.zip.padStart(5, '0');
    const slug = `${props.id}-${clinicNameSlug}-${cityNameSlug}-${zipSlug}`;

    function viewDetailOnClick(){
        let state = {fromCard:true}
        window.history.pushState(state,'marker')
    }
    return(
        <React.Fragment>
            
                <div className={"box br-10 border result-box p-4 mb-5 " + isActive}>
                    <div className={"row no-gutters "} onClick={()=>{props.getIndexClickHandler(props.index, props.id)}}>
                        <div className="col-md-6 order-2 order-md-1 text-left">
                            <h4 className="mb-3">{props.clinic.name}</h4>
                            <span><i className="fas fa-map-marker-alt mr-3" aria-hidden="true"></i>{props.city} · {distance}</span>
                            {/* <div className="mb-4 mt-3">
                        <i className="fas fa-star rated" aria-hidden="true"></i>
                        <i className="fas fa-star rated" aria-hidden="true"></i>
                        <i className="fas fa-star rated" aria-hidden="true"></i>
                        <i className="fas fa-star rated" aria-hidden="true"></i>
                        <i className="fas fa-star" aria-hidden="true"></i>
                    </div> */}
                            <div className="hours">
                                <i className="fas fa-clock  mr-2" aria-hidden="true"></i> Hours
                                </div>
                            <ul className={`mb-2 ${status.message !== "" ? "open" : "closed"}`}>
                                <li>{status.status} · {status.message}</li>
                            </ul>

                            <Link to={`/clinic/${slug}`} className="btn my-4" onClick={viewDetailOnClick}>View Details </Link>

                        </div>
                        <div className="col-md-6 text-md-right order-1 order-md-2">
                            <div className="align-middle justify-content-center mb-3">
                                <img src={(imageFile)} className="img-fluid rounded"/>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default ClinicCard;