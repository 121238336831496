import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import haversine from 'haversine-distance';
import ScrollToTop from "react-scroll-to-top";

import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Search from './components/Search';
import Results from './components/Results';
import Listings from './components/Listings';
import {
  getCurrentPositionUsingGoogleMapsAPI,
  getAddressUsingCoordinates,
  getClinicsNearZIPLocation,
  getNearestClinics
} from "./Client.js";
import './App.css';
import NotFound404Page from './components/NotFound404Page';
import Terms from './components/Terms';
import Privacy from './components/Privacy';




function App() {
  let abortController = new AbortController();
  let [loading, setLoading] = useState(false);
  let [clinics, setClinics] = useState([]);
  let [userLocation, setUserLocation] = useState({
    lat: 0,
    lng: 0
  });
  let [userAddress, setUserAddress] = useState("");
  
  let [filterSettings, setFilterSettings] = useState({
    covidTest: false,
    antibodyTest: false
  });
  
  let [radius, setRadius] = useState(20);
  let [message, setMessage] = useState('');
  let [nearestClinicsFlagZipBased, setNearestClinicsFlagZipBased] = useState(false);
  let [nearestClinicsFlagRadiusBased, setNearestClinicsFlagRadiusBased] = useState(false);

  let [userLocationDesktop, setUserLocationDesktop] = useState();
  let [userAddressDesktop, setUserAddressDesktop] = useState()

  
  // let fetchUserLocation = async () => {
  //   let userCoordinates = { lat: 0, lng: 0 }
  //   let data = await getCurrentPositionUsingGoogleMapsAPI()

  //   setUserLocation(
  //     //change before deployment//
  //     { lat: data.location.lat, lng: data.location.lng })
  //   setUserZip(data.location.zip);

  //   userCoordinates = {
  //     lat: data.location.lat, lng: data.location.lng
  //     //change before deployment//
  //     //lat:data.location.lat//, 
  //     //lng: data.location.lng
  //   }
  //   let address = await fetchUserLocationAddress(userCoordinates)
  //   setUserAddress(address ? address.trim() : "No address for user location")
  //   let params = {
  //     lat: userCoordinates.lat,
  //     long: userCoordinates.lng,
  //     radius: radius,
  //   }
  //   let clinicsNearUserLocation = await getClinicsNearZIPLocation(params, abortController);
  //   setClinics(clinicsNearUserLocation)
  //   return 
  // }

  let fetchUserLocationAddress = async (userLocation) => {
    const address_data = await getAddressUsingCoordinates(userLocation);
    let array = address_data.results.map((result)=>{
      let a = { latitude: userLocation.lat, longitude: userLocation.lng }
      let b = { latitude: result.geometry.location.lat, longitude: result.geometry.location.lng }  
      return { id:result.place_id, distance: haversine(a, b) };
    })
    let distance_array = array.map((item)=>{
      return item.distance;
    })
    
    let lowest_distance = Math.min.apply(Math, distance_array);

    let address = array.find((item)=>{
      return (item.distance === lowest_distance);
    })
    let nearest_address = address_data.results.find((item)=>{
      return(item.place_id === address.id)
    })
    let address_info;

    if (address_data) {

      const compoundCode = nearest_address.formatted_address;
      let splitCompoundCode = compoundCode.split(",");
      splitCompoundCode.reverse();
      return address_info = `${splitCompoundCode[1]}, ${splitCompoundCode[0]}`;
    }
    return address_info;
  }
//   fetchClinicsNearZIPCode = async () => {
//     const params = {
//         lat: userLocation.lat,
//         long: userLocation.lng,
//         radius: radius,
//     }

//     // this.props.onUpdateLoadingIndicator(true);
//     const clinics = await getClinicsNearZIPLocation(params, abortController);
//     // this.props.onUpdateLoadingIndicator(false);

//     return clinics;
// }
  
  useEffect(() => {
    window.scrollTo(0, 0)
    //fetchUserLocationForDesktop()
  },[])

  let fetchUserLocationForDesktop = () => {
    setLoading(true)
    let fetchedUserLocation;
    getCurrentPositionUsingGoogleMapsAPI().then(
      function (Response) {
        fetchedUserLocation =
          // change before deployment//
          //{lat: 40.78343, lng: -73.96625}
          { lat: Response.location.lat, lng: Response.location.lng }
          fetchUserLocationAddress(fetchedUserLocation).then(
            function (address) {
              setUserLocation(fetchedUserLocation)
              setUserAddress(address)
              setLoading(false)
            })
        })
  }
  
  // getting user location and clinics nearby upon loading of home page//
  let fetchUserLocationAndClinics = () => {
    setLoading(true)
    let fetchedUserLocation;
    getCurrentPositionUsingGoogleMapsAPI().then(
      function (Response) {
        fetchedUserLocation =
          // change before deployment//
          //{lat: 40.78343, lng: -73.96625}
          { lat: Response.location.lat, lng: Response.location.lng }
        let params = {
          lat: Response.location.lat,
          long: Response.location.lng,
          radius: radius,
        }
        getClinicsNearZIPLocation(params, abortController).then(
          function (clinics) {
            setUserLocation(fetchedUserLocation)
            if (clinics.length < 1) {
              setLoading(true)
              getNearestClinics({ lat: fetchedUserLocation.lat, long: fetchedUserLocation.lng }, abortController).then(
                function (res) {
                  setClinics(res)
                  setMessage('There are no clinics within a '+radius+ 'mi radius. Showing you nearest clinic from your location.');
                  setLoading(false)
                })
            } else {
              // setUserLocation(fetchedUserLocation)
              setClinics(clinics)
              setLoading(false)
            }

            fetchUserLocationAddress(fetchedUserLocation).then(
              function (address) {
                setUserAddress(address)
                
              })
          })
      });
  }

  function initialState(){
    setClinics([]);
    setFilterSettings({
      covidTest: false,
      antibodyTest: false
    })

    setUserLocation({
      lat: 0,
      lng: 0
    });
    setUserAddress("");
  
    setRadius(20);
    setMessage('');
    setNearestClinicsFlagZipBased(false);
    setNearestClinicsFlagRadiusBased(false);
  }
  
  function logoOnClickHandler(){
    initialState()
  }



  return (

    <div className="App">
      <BrowserRouter>
        <Navbar logoOnClickHandler={logoOnClickHandler}
                setFilterSettings={setFilterSettings}
                filterSettings={filterSettings} />

        <Route path="/" exact component={() =>
          <Home setFilterSettings={setFilterSettings}
            initialState = {initialState}
            filterSettings={filterSettings}
            userLocation={userLocation}
            clinics={clinics}
            setClinics={setClinics}
            loading = {loading} />}
        />
        <Route path="/about" exact component={AboutUs} />
        <Route path="/search" exact component={() =>
          <Search userLocation={userLocation}
            setUserLocation={setUserLocation}
            setUserAddress={setUserAddress}
            userAddress={userAddress}
            loading={loading}
            setLoading={setLoading}
            filterSettings={filterSettings}
            setFilterSettings={setFilterSettings}
            radius={radius}
            setRadius={setRadius}
            fetchUserLocationAndClinics={fetchUserLocationAndClinics}
            clinics={clinics}
            setClinics={setClinics}
            message = {message}
            setMessage = {setMessage}
            setNearestClinicsFlagZipBased = {setNearestClinicsFlagZipBased}
            nearestClinicsFlagZipBased = {nearestClinicsFlagZipBased}
            nearestClinicsFlagRadiusBased = {nearestClinicsFlagRadiusBased}
            setNearestClinicsFlagRadiusBased = {setNearestClinicsFlagRadiusBased}
          />}
        />
        <Route path="/results" exact component={() =>
          <Results userLocation={userLocation}
            setUserLocation={setUserLocation}
            setUserAddress={setUserAddress}
            userAddress={userAddress}
            loading={loading}
            setLoading={setLoading}
            filterSettings={filterSettings}
            setFilterSettings={setFilterSettings}
            radius={radius}
            setRadius={setRadius}
            fetchUserLocationAndClinics={fetchUserLocationAndClinics}
            clinics={clinics}
            setClinics={setClinics}
            message = {message}
            setMessage = {setMessage}
            setNearestClinicsFlagZipBased = {setNearestClinicsFlagZipBased}
            nearestClinicsFlagZipBased = {nearestClinicsFlagZipBased}
            nearestClinicsFlagRadiusBased = {nearestClinicsFlagRadiusBased}
            setNearestClinicsFlagRadiusBased = {setNearestClinicsFlagRadiusBased}
          />}
        />
        <Route path="/clinic/:slug" exact render={({ match }) => 
          <Listings match={match} />} 
        />
        <Route path="/privacy-policy" exact component={Privacy} />
        <Route path="/terms-of-use" exact component={Terms} />
        <Route path="/404" exact component={NotFound404Page} />
        
        <Footer logoOnClickHandler={logoOnClickHandler} />
      </BrowserRouter>
      <div>
        <ScrollToTop smooth color="#6f00ff" />
      </div>
    </div>

  );
}

export default App;
