import React, { useState, useEffect } from 'react';
import {
    getAddressUsingZIP, getClinicsNearZIPLocation, getNearestClinics, getCurrentPositionUsingGoogleMapsAPI,
    getAddressUsingCoordinates, getClinicsNearZIPLocationV2
} from "../Client";
import { Redirect } from 'react-router-dom';
import { isMobile, isBrowser } from "react-device-detect";
import haversine from 'haversine-distance';
import Helmet from 'react-helmet';



function Search(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let abortController = new AbortController();

    let [userInputZip, setUserInputZip] = useState();
    let [errorMessage, setErrorMessage] = useState("");
    let [error, setError] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    
    function zipOnChangeHandler(e){
        setError(false);
        let value = e.target.value;
            setUserInputZip(value)
    }

    let onFetchZIPAddressResult = (data) => {
        if (data) {
            if (data.results.length > 0) {
                if (data.results[0].types && data.results[0].types[0] === "postal_code") {
                    const address = data.results[0].formatted_address;
                    return {
                        address: address,
                        coordinates: {
                            lat: data.results[0].geometry.location.lat,
                            lng: data.results[0].geometry.location.lng
                        }
                    };
                }
                else {
                    return {
                        address: "Invalid ZIP code",
                        coordinates: {
                            lat: props.userLocation.lat,
                            lng: props.userLocation.lng
                        }
                    };
                }
            }

            return null;
        }

        return null;
    }

    async function getAddress(zip){
        setIsLoading(true)
        return getAddressUsingZIP(zip).then(
            function(data){ 
                let addressInformation = onFetchZIPAddressResult(data)
                setIsLoading(false)
                return addressInformation 
            }
        )
    }
    
    // async function fetchClinics(addressInformation){
    //     setIsLoading(true)
    //     let params = {
    //         lat: addressInformation.coordinates.lat,
    //         long: addressInformation.coordinates.lng,
    //         radius: props.radius,
    //     }
    //     return getClinicsNearZIPLocation(params, abortController).then(
    //         function(clinics){
    //             if (clinics.length < 1) {
    //                 return getNearestClinics({ lat: addressInformation.coordinates.lat, long: addressInformation.coordinates.lng }, abortController).then(
    //                   function (res) {
    //                     props.setClinics(res)
    //                     props.setMessage(`There are no testing centers from the zip code you have entered. Showing you the nearest testing center${res.length > 1 ? "s" : ""} from it.`);
    //                     setIsLoading(false)
    //                     return res
    //                 })
    //             } else {
    //                 props.setClinics(clinics)
    //                 props.setMessage(`There are ${clinics.length} testing center${clinics.length > 1 ? "s" : ""} near you.`)
    //                 setIsLoading(false)
    //                 return clinics
    //             } 
    //         }
    //     )
    // }

    async function fetchClinics(addressInformation){
        setIsLoading(true)
        let params = {
            lat: addressInformation.coordinates.lat,
            long: addressInformation.coordinates.lng,
            radius: props.radius,
        }
        return getClinicsNearZIPLocationV2(params, abortController).then(
            function(clinics){
                console.log(clinics);
                // show nearest clinics if there are no clinics available in the area
                if (clinics.length < 1) {
                    getNearestClinics({ lat: addressInformation.coordinates.lat, long: addressInformation.coordinates.lng }, abortController).then(
                      function (res) {
                        props.setUserLocation({
                            lat: addressInformation.coordinates.lat,
                            lng: addressInformation.coordinates.lng
                        })
                        props.setClinics(res)
                        props.setNearestClinicsFlagRadiusBased(false)
                        props.setNearestClinicsFlagZipBased(true)
                        // props.setMessage(`There are no testing centers from the zip code you have entered. Showing you ${res.length} nearest testing center${res.length > 1 ? "s" : ""}.`);
                        setIsLoading(false)                    
                    })

                } else {
                    props.setUserLocation({
                        lat: addressInformation.coordinates.lat,
                        lng: addressInformation.coordinates.lng
                    })
                    props.setClinics(clinics)
                    props.setNearestClinicsFlagRadiusBased(false)
                    props.setNearestClinicsFlagZipBased(false)
                    // props.setMessage(`There are ${clinics.length} testing center${clinics.length > 1 ? "s" : ""} near you.`)
                    setIsLoading(false)
                    return clinics
                } 
            }
        )
    }

    // function clearData(){
    //     setDisplayedClinics([]);
    //     document.getElementById('resultMessage').innerHTML = "";
    //     document.getElementById('resultAddress').innerHTML = "";
    // }

    // get geolocation if on browser
    let fetchUserLocationAddress = async (userLocation) => {
        const address_data = await getAddressUsingCoordinates(userLocation);
        let array = address_data.results.map((result)=>{
          let a = { latitude: userLocation.lat, longitude: userLocation.lng }
          let b = { latitude: result.geometry.location.lat, longitude: result.geometry.location.lng }  
          return { id:result.place_id, distance: haversine(a, b) };
        })
        let distance_array = array.map((item)=>{
          return item.distance;
        })
        
        let lowest_distance = Math.min.apply(Math, distance_array);
    
        let address = array.find((item)=>{
          return (item.distance === lowest_distance);
        })
        let nearest_address = address_data.results.find((item)=>{
          return(item.place_id === address.id)
        })
        let address_info;
    
        if (address_data) {
    
          const compoundCode = nearest_address.formatted_address;
          let splitCompoundCode = compoundCode.split(",");
          splitCompoundCode.reverse();
          return address_info = `${splitCompoundCode[1]}, ${splitCompoundCode[0]}`;
        }
        return address_info;
    }

    function fetchClinicsNearUserLocation() {
        setIsLoading(true);
        let fetchedUserLocation;
        getCurrentPositionUsingGoogleMapsAPI().then(
          function (Response) {
            fetchedUserLocation =
              // change before deployment//
              //{lat: 40.78343, lng: -73.96625}
              { lat: Response.location.lat, lng: Response.location.lng }
            let params = {
              lat: Response.location.lat,
              long: Response.location.lng,
              radius: props.radius,
            }
            getClinicsNearZIPLocation(params, abortController).then(
                function (clinics) {
                    if (clinics.length < 1) {
                        getNearestClinics({ lat: params.lat, long: params.long }, abortController).then(
                            function (res) {
                                props.setUserLocation({ lat: params.lat, long: params.long })
                                props.setClinics(res)
                                setIsLoading(false);
                                //   props.setMessage('There are no clinics within a '+params.radius+ 'mi radius. Showing you nearest clinic from your location.');
    
                            })
                    } else {
                        props.setUserLocation({ lat: params.lat, long: params.long })
                        props.setClinics(clinics)
                        setIsLoading(false);
    
                    }
                }
            )
        })
        

    }

    // let fetchUserLocationAndClinics = () => {
    //     setIsLoading(true);
    //     let fetchedUserLocation;
    //     getCurrentPositionUsingGoogleMapsAPI().then(
    //       function (Response) {
    //         fetchedUserLocation =
    //           // change before deployment//
    //           //{lat: 40.78343, lng: -73.96625}
    //           { lat: Response.location.lat, lng: Response.location.lng }
    //         let params = {
    //           lat: Response.location.lat,
    //           long: Response.location.lng,
    //           radius: props.radius,
    //         }
    //         getClinicsNearZIPLocation(params, abortController).then(
    //           function (clinics) {
    //             props.setUserLocation(fetchedUserLocation)
    //             if (clinics.length < 1) {
    //               getNearestClinics({ lat: fetchedUserLocation.lat, long: fetchedUserLocation.lng }, abortController).then(
    //                 function (res) {
    //                   props.setClinics(res)
    //                 //   props.setMessage('There are no clinics within a '+params.radius+ 'mi radius. Showing you nearest clinic from your location.');
                    
    //                 })
    //             } else {
    //               props.setUserLocation(fetchedUserLocation)
    //               props.setClinics(clinics)
                  
    //             }
    
    //             fetchUserLocationAddress(fetchedUserLocation).then(
    //               function (address) {
    //                 props.setUserAddress(address)
    //                 setIsLoading(false);
    //             })
    //         })
    //     });
          
    // }


    async function zipOnClickHandler() {
        localStorage.removeItem('userZip');
        if (userInputZip !== undefined) {
            if(userInputZip === ''){
                if(isMobile){
                    setErrorMessage("Please enter zip code")
                    setError(true)
                } else if(isBrowser){
                    props.fetchUserLocationAndClinics()
                    //fetchClinicsNearUserLocation()
                }
            } else {
                let zip = userInputZip.replace(/^0+/, '');
                let addressInformation = await getAddress(zip);
                if(addressInformation === null || addressInformation.address === "Invalid ZIP code"){ 
                    setErrorMessage('Please enter a valid zip code.')
                    setError(true)
                } else {
                    
                    let clinics = await fetchClinics(addressInformation);
                    props.setUserAddress(addressInformation.address)
                }
            }
        } else if (userInputZip === undefined){
            if(isMobile){
                setErrorMessage("Please enter zip code")
                setError(true)
            } else if(isBrowser){
                props.fetchUserLocationAndClinics()
                //fetchClinicsNearUserLocation()
            }
            
        }       
    }


    function errorDisplay(){
        if(error){
            return(
                <p className="mt-2 mb-0 mx-auto"><small className="text-center" style={{color: "red"}}>{errorMessage}</small></p>
                )
        } else{
            return
        }
    }

    function loadingDisplay(){
        if(isLoading || props.loading){
            return(
                <>
                    <i className="fa fa-spinner loading-icon" aria-hidden="true"></i>
                    <p className="mt-2 mb-0"><small>Please wait for a moment...</small></p>
                </>
            )
        } else {
            return(
                <button className="btn btn-cta-2"
                    onClick={zipOnClickHandler} >
                        Find My Location
                </button>
            )
        }
    }

   
    
    return (
        <React.Fragment>
            <Helmet>
                <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation and COVID or Antibody Testing - Search</title>
                <meta name="description" content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available." />
                <meta name="keywords" content="antibody testing, covid test, antibody testing near me, covid test near me" />
            </Helmet>
                {(props.clinics.length > 0 ) &&
                <Redirect to='/results' />
                }
                <div className="section" id="search">
                    <div className="container">
                        <div className="row">
                            <div className="col text-left">
                                <img src="../../img/ico/magnifying.svg" className="mb-4" alt="search"/>
                                <h1 className="large">Find the nearest testing center</h1>
                                <p>Get The Care You Need Right Now</p>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col-sm-12 col-md-10 col-lg-6 col-xl-5">
                                <div className="find-box animated fadeInUp box br-10 shadow p-5 my-5 ">
                                    <p className="lead mb-4 text-center">
                                        Find local testing centers
                                    </p>
                                    <div className="row m-0 py-3 px-4 border rounded">
                                        <div className="col-12 col-md-8 col-lg-4 pl-0 text-left">
                                            <label>Zip Code</label>
                                            <input type="text"
                                                className={"form-control mb-3 mb-lg-0 mb-xl-0 w-100 " + (isMobile ? "mobile-search-input" : "")}
                                                onChange={zipOnChangeHandler}
                                                placeholder=""
                                                required="" />
                                                
                                        </div>
                                        
                                        <div className="col-12 col-md-12 col-lg-8 px-0 text-center">
                                              
                                                   {loadingDisplay()}
    
                                        </div>
                                        
                                        {errorDisplay()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

    
    )
}

export default Search;