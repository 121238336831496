import React from 'react';

const ServicesOffered = ({title, services}) => {
    const service = services.services

    let servicesDisplay = service.split(",").map(services => {
       
        return(
            <dd  key={services.index}> - {services.replace(services.charAt(0),services.charAt(0).toUpperCase())}</dd>
        )
    })

    return (
        <>
            {(service.trim() === '' ||  service.trim().length === 0 )?
            null :

            <div className="col-lg-6 mb-3 mb-md-3 mb-lg-0 ">
                <div className="box border p-4 p-md-5 m-1 m-md-0 br-20 diff-box h-100">
                    <img src="../../img/ico/services-we-offer.svg" alt="..." className="mb-4"/>
                    <h5 className="mb-5">{title}</h5>
                    {(service.trim() === '' ||  service.trim().length === 0 )?
                     <p className="no-services"><br/><br/><br/><br/>Please call for more information.</p>:
                     <p className="mb-4">
                     <dl className="mb-4">
                        {servicesDisplay}
                     </dl>
                     </p>
                    }
                </div>
            </div>
            }
        </>
    )
}

export default ServicesOffered;