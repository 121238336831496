import React from 'react';
import { Link }from 'react-router-dom'



function FindNearestCard(props){
    function findNearestResultClickHandler(id){
        if(id===1){
            props.setFilterSettings({...props.filterSettings, 
                covidTest: true,
                antibodyTest: false,
            });
            
        } else {
            props.setFilterSettings({...props.filterSettings, 
                covidTest: false,
                antibodyTest: true,
            });
        }
    }

    return(

        <div className="col-md-6 col-lg-5 d-inline-flex   mb-md-0 mb-3">
            <div className="box text-center border p-5 py-md-5 p-md-2 br-20 shadow animated fadeInUp delay-1">
                <img src={('../../img/ico/' + props.card.image)} alt={(props.card.header_name)} className="mb-4"/>
                    <h4 className="pb-1">{props.card.header}</h4>
                    <h3 className={("pb-3 " + props.card.color)}>{props.card.header_name}</h3>
                    <p className="mb-5  px-md-5">{props.card.description}</p>

                    <Link to="/search" className={props.card.button_color} value={props.card.id} onClick={()=>{findNearestResultClickHandler(props.card.id)}}>Find Nearest Result</Link>
                </div>
        </div>
    );
}
export default FindNearestCard;