import React from 'react';
import {getGoogleMapsAPIAccessToken} from '../../Client';

const SingleLocationMapSection = ({lat, lng}) => {
    const src = `https://www.google.com/maps/embed/v1/place?key=${getGoogleMapsAPIAccessToken()}` +
                `&q=${lat},${lng}` +
                `&zoom=16`;
    return (
        <iframe
            title="Location Map Section"
            width="600"
            height="450"
            frameBorder="0"
            style={{border: 0}}
            src={src}
            allowFullScreen>
        </iframe>
    )
}

export default SingleLocationMapSection;