import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

const NotFound404Page = ({underConstruction}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="section py-5" style={{
            backgroundColor: "rgb(246, 248, 250)",
        }}>
            <Helmet>
                <title>AntibodyTesting.com: Find Your Local Testing Center, Get Directions, Hours of Operation and COVID or Antibody Testing - Page Not Found</title>
                <meta name="description" content="Find a local medical center that offers Covid or Antibody Testing from our nationwide database. Listing details include COVID and Antibody Testing information, type of testing, accepted insurance carriers, hours, and other services. At home testing also available." />
                <meta name="keywords" content="antibody testing, covid test, antibody testing near me, covid test near me" />
            </Helmet>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-12  ">
                        <img src={('../../img/404.svg')} style={{
                            width: "290px",
                        }} alt="Page not found"/>
                        <h1 className="pt-md-5 pb-md-3">{(underConstruction ?? false) ? "Page under construction" : "Page not found"}</h1>
                        <p className="pb-md-5">{(underConstruction ?? false) ? "Oops! Page is still under construction. Please, come back soon and try again." :
                                                                               "The page you requested could not be found."}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound404Page;