import React from 'react';

const Insurance = ({title, insuranceCompany}) => {
    let cleanedInsurance = [];
    console.log('INSURANCE');
    console.log(insuranceCompany);
    if(insuranceCompany) {
        cleanedInsurance = insuranceCompany.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim());
    }

    return (
        <>
            {(cleanedInsurance.length < 1 )?
                null:

                <div className="col-lg-6 mb-3 mb-md-3 mb-lg-0 ">
                    <div className="box border p-4 p-md-5 m-1 m-md-0 br-20 diff-box mh-100">
                        <img src="../../img/ico/insurance-carriers.svg" alt="..." className="mb-4"/>
                        <h5 className="mb-5">{title}</h5>
                        {(cleanedInsurance.length < 1) ?
                        <p className="no-insurance"><br/><br/><br/><br/>Please call for more information.</p> :
                        <p className="mb-4"></p>
                        }
                       <dl className="mb-4">
                           {cleanedInsurance.map(insurances =>(
                                <dd key={insurances}>
                                    - {insurances}
                                </dd>
                           ))}
                        </dl>
                    </div>
                </div>
            }
        </>
    )
}

export default Insurance;