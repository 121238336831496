import React from 'react';

const MapSent = ({ mobileNumber}) => {
    
    
        return(
            <>
                <div className="modal fade  modal-success " id="sms-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            
                            <div className="modal-body">
                            <div className="text-center">

                                <img src= "/img/location.svg" className="mt-5"  alt= '...' />
                                <h2 className="mt-4 mb-2">SMS Sent!</h2>
                                <p className="mb-4 pb-2">The information was sent to {mobileNumber}.</p>
                                
                                <a className="d-inline-block sms-btn round-border-10 py-2 px-3 mb-3 mb-md-4"  data-dismiss="modal">OK</a>
                            </div>
                            </div>
                        </div>
                            
                    </div>
                </div>

            </>
        );
    
}

export default MapSent;