import React from 'react';
import jsonData from '../jsonData'
import CompareSectionCard from './CompareSectionCard'

function CompareSection(){
    let data = jsonData.compare_section;
    let cards = data.cards;

    let cardDisplay = cards.map( card => {
        return(
            <CompareSectionCard key={card.id} card={card}/>
        )
    })
    return(
    <div className="section difference">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center txt-white">
                    <img src={("../img/ico/" + data.image)} alt="" className="mb-5" />
                    <p className="lead txt-white mb-4">{data.subheader}</p>
                    <h1 className="txt-white mb-5">{data.header} </h1>
                </div>
            </div>
            <div className="row justify-content-center text-left">
                {cardDisplay}
            </div>
        </div>
    </div>
    );
}

export default CompareSection;