import React from 'react';
import jsonData from '../jsonData'


function InTheKnowSection(){

    let data = jsonData.info_rows;
    let iconsList = jsonData.icons_list;

    let iconsDisplay = iconsList.map(icon => {
        return(
        <div key={icon.id} className="col-3 col-md-6">
            <img src={("../img/ico/" + icon.image)} className="img-fluid p-2" alt="" />
        </div>
        )
    });

    return(
    <div className="section pt-5 pt-md-5 pt-lg-0">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <h2 className="text-center mb-3">{data.header}</h2>
                    <div className="separator-line mx-auto"></div>
                </div>
            </div>
            <div className="row my-5 pb-5 text-left">
                <div className="col-md-6 col-lg-6 order-md-2 offset-lg-1">
                    <h4 className="mb-4">{data.first_row.header}</h4>
                    <p>{data.first_row.p1}</p>
                    <p>{data.first_row.p2}</p>
                </div>
                <div className="col-md-6 col-lg-5 ">
                    <img src={("../img/" + data.first_row.image)} className="img-fluid" alt=""/>
                </div>
            </div>
            <div className="row my-5 pb-5 text-left">
                <div className="col-md-6 ">
                    <h4 className="mb-4">{data.second_row.header}</h4>
                    <p>{data.second_row.p1}</p>
                </div>
                <div className="col-md-5 offset-md-1 pb-5">
                    <img src={("../img/" + data.second_row.image)} className="img-fluid" alt=""/>
                </div>
            </div>
            <div className="row my-5 pb-5 text-left">
                <div className="col-md-6 order-md-2 offset-md-1">
                    <h4 className="mb-4">{data.third_row.header}</h4>
                    <p>{data.third_row.p1}</p>
                    <p>{data.third_row.p2}</p>
                </div>
                <div className="col-md-4 ">
                    <img src={("../img/" + data.third_row.image)} className="img-fluid" alt=""/>
                </div>
            </div>
            <div className="row text-left">
                <div className="col-md-6">
                    <img src={("../img/ico/" + data.fourth_row.image)} className="mb-5" alt=""/>
                    <h4 className="mb-4">{data.fourth_row.header}</h4>
                    <p>{data.fourth_row.p1}</p>
                    <p>{data.fourth_row.p2}</p>
                    <p>{data.fourth_row.p3}</p>
                    <p>{data.fourth_row.p4}</p>
                    <p>{data.fourth_row.p5}</p>
                    <p>{data.fourth_row.p6}</p>
                    <p>{data.fourth_row.p7}</p>
                    <p>{data.fourth_row.p8}</p>
                </div>
                <div className="col-md-6 col-lg-4 offset-lg-2 text-center">
                    <div className="row no-gutters justify-content-center">
                        {iconsDisplay}
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
}

export default InTheKnowSection;